import AgentSidebar from 'agentfolder/sidebar/agentSideBar'
import React from 'react'

const AgentSale = () => {
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AgentSidebar/>
    </div>
    <div className='div-Dashbaord'>
      <div className='row-div-admin-dashboard'>
    <h1 className='h1-all-heading'>Agent Sale </h1>
    
    
    </div>
    <div className="dashboard">
     
      <div className='styles-div'>

      
      
      </div>
     
    </div>
    </div>
    </div>
  )
}

export default AgentSale