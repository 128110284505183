import Footer from 'components/Footer'
import Header from 'pages/Home1/Header'
import React from 'react'
import WashtaLogo from '../../assets/images/WashtaLogo.png'
const NotVerifiedUser = () => {
  return (
    <>
         <Header />

    <div style={{ position: 'relative',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <div>
            <img src={WashtaLogo} className='image-div-class-not-verify'/>
            <h1 style={{textAlign:'center'}} className='h1-all-heading'>You verification request is under process please wait!.......... 
        <br></br>
        <span style={{color:'#747eef',fontFamily:'"Manrope", sans-serif',paddingTop:10}}>Thank You</span>
        </h1>
        </div>
        
        </div>
        <Footer/>
        </>
  )
}

export default NotVerifiedUser