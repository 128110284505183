import React from 'react'
import './Supportcomp.css'
const ContactIconSupport = ({Icon,Heading,timing,timingSec}) => {
  return (
    <div className='supportcom-icon-conact'>
    <img src={Icon} className='image-support-div-img-conact-seupprot'/>
    <div className='dic-contact-suport'>
    <h5 className='h5-heaidng-invouice'>{Heading}</h5>
    <p className='paragraph-text-shop' style={{padding:'0',margin:0}}>{timing}</p>
    <p className='paragraph-text-shop' style={{padding:'0',margin:0}}>{timingSec}</p>
    </div>
    
    </div>
  )
}

export default ContactIconSupport