
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect } from 'react'
import AllCustomerCom from '../AllCustomerCom';
import { useNavigate } from 'react-router-dom';
// /super-admin-dashboard/customers/all-customers/compan-details
const ViewAllCompanies = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const navigator  = useNavigate()
      const data = [
       {
        id:1,
        name:'henry',
        time:'10:00 AM',
        status:'Pending',
        totalSpent:'2500',
        location:'Dubai, UAE',
        price:'30',
        raduis:'5 Km',
        service:'Car Washing',
        img:'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg',
        
        Booking:[
            {
            id:1,
            bookingId:'# A-1234',
            date:'16 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'100',
        },
        {
            id:2,
            bookingId:'# A-2214',
            date:'17 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'120',
        },
        {
            id:3,
            bookingId:'# A-1234',
            date:'12 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'140',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        
        ]
    },
    {
        id:2,
        name:'Kristin Watson',
        time:'10:00 AM',
        status:'Completed',
        totalSpent:'1500',
        location:'Dubai, UAE',
        raduis:'10 Km',
        price:'70',
        service:'Car Wash',
        img:'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg',

        
        Booking:[
            {
            id:1,
            bookingId:'# A-1234',
            date:'16 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'100',
        },
        {
            id:2,
            bookingId:'# A-2214',
            date:'17 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'120',
        },
        {
            id:3,
            bookingId:'# A-1234',
            date:'12 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'140',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        
        ]
    }
      ]
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>All Companies </h1>
    <div className="dashboard">    
      <div className='styles-div addtionwidth-setting'>
        {data.map((item)=>(
            <div key={item.id}>
                <AllCustomerCom
                Imaag={item.img}
                // item={item}
                onclick={()=> navigator('/super-admin-dashboard/companies/all-companies/companies-details',{ state: { item: item } })}
                 status={item.status} 
                 name={item.name}
                payment={item.totalSpent}
                date={item.time}/>
            </div>

        ))}
   
      
      </div>
    </div>
    </div>
    </div>
  )
}

export default ViewAllCompanies