import React from 'react';
import ClipBord from '../../../assets/images/usertopcustomer.png'
import Imgaaa from '../../../assets/images/profileimagescreen.png';

function TopCustomers() {
  const Data =[
    {id:1,
    name:'Kristin Watsonasdasdasdasd',
   vist :'4 Vist',
   spent:'2500323423423'
  },
  {id:2,
    name:'Harry Doe',
    vist :'5 Vist',
    spent:'2500'
  },
  {id:3,
    name:'Jhon Doe',
    vist :'10 Vist',
   spent:'2500'
  },
  {id:4,
    name:'Harry',
    vist :'4 Vist',
   spent:'1500'
  }
]
  return (
    <div className="orders">
      <div className='chart-colm-div-heading'> 
          <img src={ClipBord} style={{width:'13%',objectFit:'contain'}}/>
          <h2 className='main-heading2'>Top Customers</h2>
          </div>
      <div className="order-list">
        {Data.map((data) => {
          console.log(data)
          return(
          <div key={data.id} >
            <div className='second-row-customer customer-pending' style={{gap:'20px'}} >
             <div style={{width:'60%',display:'flex',gap:'5px'}}>
             <img src={Imgaaa} style={{width:'20%',objectFit:'cover'}}/>
              <div className='top-custorm-width'>
                <h5 className='h5-class-top-class'>{data.name}</h5>
                <p className='paragprh-customer-com'>{data.vist}</p>
              </div>
             </div>
             
              <div className='top-custorm-width' style={{width:'30%', textAlign:'start'}}>
                <p className='paragprh-customer-com'>Total Spent</p>
                <h5 className='h5-class-top-class'>{data.spent}<span style={{fontSize:9}}>AED</span></h5>
              </div>
            </div>
            </div>
            )
           
        })}
      </div>
    </div>
  );
}

export default TopCustomers;
