import React, { useState } from 'react';
import { ImCross } from "react-icons/im";
import { ToastContainer, toast } from 'react-toastify';
import { MdOutlineAttachment } from "react-icons/md";
import 'react-toastify/dist/ReactToastify.css';
import { TimePicker, Space } from 'antd';
import 'antd/dist/reset.css';
import moment from 'moment';
import Switch from 'react-switch';
import Select from 'react-select';
import { GoogleMap, LoadScript, Marker, Circle } from '@react-google-maps/api';
import axios from 'axios';
import './popup.css';

const cities = [
  { value: 'Deira', label: 'Deira, Dubai', lat: 25.271139, lng: 55.307485 },
  { value: 'Bur Dubai', label: 'Bur Dubai, Dubai', lat: 25.250970, lng: 55.300184 },
  { value: 'Jumeirah', label: 'Jumeirah, Dubai', lat: 25.193522, lng: 55.243645 },
  { value: 'Downtown Dubai', label: 'Downtown Dubai, Dubai', lat: 25.197197, lng: 55.274376 },
  { value: 'Dubai Marina', label: 'Dubai Marina, Dubai', lat: 25.080311, lng: 55.140377 },
  { value: 'Palm Jumeirah', label: 'Palm Jumeirah, Dubai', lat: 25.112297, lng: 55.138572 },
  { value: 'Business Bay', label: 'Business Bay, Dubai', lat: 25.184597, lng: 55.260956 },
  { value: 'Abu Dhabi', label: 'Abu Dhabi', lat: 24.453884, lng: 54.377344 },
  { value: 'Sharjah', label: 'Sharjah', lat: 25.346255, lng: 55.420932 },
  { value: 'Ajman', label: 'Ajman', lat: 25.405216, lng: 55.513643 },
  { value: 'Ras Al Khaimah', label: 'Ras Al Khaimah', lat: 25.800692, lng: 55.976200 },
  { value: 'Fujairah', label: 'Fujairah', lat: 25.128809, lng: 56.326485 },
  { value: 'Umm Al Quwain', label: 'Umm Al Quwain', lat: 25.564733, lng: 55.555176 }
];


const PopupSellar = ({ Cross, id ,fetchData}) => {
  const [step, setStep] = useState(1);
  fetchData()
  const [formData, setFormData] = useState({
    Owner: id,
    shopName: '',
    shopDetails: '',
    cost: '',
    uploadCover: null,
    city: '',
    radius: '',
    locationtype: '',
    markerPosition: { lat: 25.276987, lng: 55.296249 }, // Default to Dubai
    timings: {
      monday: { start: '', end: '', active: false },
      tuesday: { start: '', end: '', active: false },
      wednesday: { start: '', end: '', active: false },
      thursday: { start: '', end: '', active: false },
      friday: { start: '', end: '', active: false },
      saturday: { start: '', end: '', active: false },
      sunday: { start: '', end: '', active: false },
    }
  });
  const [uploading, setUploading] = useState(false);
  const [fileName, setFileName] = useState('');

 const token = localStorage.getItem('accessToken')
 console.log(token)
  const handleTimeChange = (day, key, time) => {
    setFormData({
      ...formData,
      timings: {
        ...formData.timings,
        [day]: {
          ...formData.timings[day],
          [key]: time ? time.format('h:mm a') : '',
        },
      },
    });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, uploadCover: file });
    setFileName(file ? file.name : '');
  };
  
  const handleToggleChange = (day) => {
    setFormData({
      ...formData,
      timings: {
        ...formData.timings,
        [day]: {
          ...formData.timings[day],
          active: !formData.timings[day].active,
        },
      },
    });
  };

  const handleCityChange = (selectedOption) => {
    setFormData({
      ...formData,
      city: selectedOption.value,
      markerPosition: { lat: selectedOption.lat, lng: selectedOption.lng },
    });
  };

  const handleMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setFormData({
      ...formData,
      markerPosition: { lat, lng },
    });
  };

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    console.log("Geocoding address:", address);  // Log the address
    geocoder.geocode({ address: address }, (results, status) => {
      console.log("Geocode results:", results);  // Log the results
      console.log("Geocode status:", status);    // Log the status
      if (status === 'OK') {
        const position = results[0].geometry.location;
        setFormData({
          ...formData,
          markerPosition: { lat: position.lat(), lng: position.lng() },
        });
      } else {
        console.log('Geocode was not successful for the following reason: ' + status);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'locationtype' && value.trim() !== '') {
      geocodeAddress(value.trim());
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.uploadCover) {
      toast.error('Please select an image to upload');
      return;
    }

    try {
      setUploading(true);

      // Upload the image
      const uploadFormData = new FormData();
      uploadFormData.append('file', formData.uploadCover);

      const uploadResponse = await axios.post('https://washta-9006f93279b8.herokuapp.com/api/uploadFile', uploadFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // Include authentication token if needed
          'Authorization': `Bearer ${token}`
        }
      });

      const documentUrl = uploadResponse.data.data.path;
      console.log('File URL:', documentUrl);

      // Add the document URL to the form data
      const completeFormData = {
        ...formData,
        coverImage: documentUrl
      };

      // Make the API call with the complete form data
      const response = await axios.post('https://washta-9006f93279b8.herokuapp.com/api/seller/shop', completeFormData, {
        headers: {
          'Content-Type': 'application/json',
          // Include authentication token if needed
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        toast.success('Shop details submitted successfully!');
        Cross();
      } else {
        toast.error('Failed to submit shop details');
      }
      // Cross();
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error submitting shop details');
    } finally {
      setUploading(false);
    }
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  return (
    <div className="app">
      <div className="popup-background">
        <div className="popup">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2>Shop {step === 1 ? "Details" : step === 2 ? "Timings" : "Location"}</h2>
            <ImCross style={{ color: '#8891F1', cursor: 'pointer' }} onClick={Cross} className="close-icon" />
          </div>
          {step === 1 && (
            <>
              <label className='label-popup-seller'>Shop Name <sup>*</sup></label>
              <input
                type="text"
                name="shopName"
                placeholder="Shop name*"
                value={formData.shopName}
                onChange={handleChange}
              />
              <label className='label-popup-seller'>Shop Details <sup>*</sup></label>
              <input
                type="text"
                name="shopDetails"
                placeholder="Shop details"
                value={formData.shopDetails}
                onChange={handleChange}
              />
              <div className='addtion-spantag'>
              <label className='label-popup-seller '>Cost <sup>*</sup></label>
              <input
                type="text"
                name="cost"
                placeholder="AED"
                value={formData.cost}
                onChange={handleChange}
              />
              <span className='span-tag-popup'>AED</span>
              </div>
              <div className='gapmargin' style={{ display: 'flex', position: 'relative', }}>
              <input
                  type="file"
                  id="fileUpload"
                  accept=".jpeg,.jpg,.png"
                  className='inputfeild-sellar'
                  onChange={handleFileChange}
                  style={{ display: 'none', }}
                />
                <label htmlFor="fileUpload" style={{ borderBottom: '1px solid #c3c3c3', cursor: 'pointer', width: '100%', color: 'black', paddingBottom: 10, fontSize: '14px', paddingBottom: '1em' }}>
                  {fileName || "Upload Shop Image"}
                </label>

                <p style={{ position: 'absolute', right: '2em', color: '#c3c3c3', fontSize: '14px', fontFamily: '"Manrope", sans-serif' }}>(jpeg, jpg, png)</p>
                <MdOutlineAttachment style={{ position: 'absolute', right: '10px', color: '#c3c3c3', fontSize: '18px', transform: 'rotate(130deg)' }} />
              </div>
              <div className='row-popup'>
                <button className='btn-left' onClick={Cross}>Cancel</button>
                <button className='btn-right' onClick={nextStep}>Continue</button>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
                <div key={day} className="timing-row">
                  <div className='week-part'>
                    <label>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                    
                  </div>
                  <div className="timing-row">
                    <Space direction="vertical" size={12}>
                      <TimePicker
                        value={formData.timings[day].start ? moment(formData.timings[day].start, 'h:mm a') : null}
                        onChange={(time) => handleTimeChange(day, 'start', time)}
                        use12Hours
                        format="h:mm a"
                        disabled={!formData.timings[day].active}
                      />
                    </Space>
                    <Space direction="vertical" size={12}>
                      <TimePicker
                        value={formData.timings[day].end ? moment(formData.timings[day].end, 'h:mm a') : null}
                        onChange={(time) => handleTimeChange(day, 'end', time)}
                        use12Hours
                        format="h:mm a"
                        disabled={!formData.timings[day].active}
                      />
                    </Space>
                    <Switch
                      onChange={() => handleToggleChange(day)}
                      checked={formData.timings[day].active}
                      onColor="#747EEF"
                      offColor="#bcbcbc"
                      handleDiameter={17}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={36}
                    />
                  </div>
                </div>
              ))}
              <div className='row-popup'>
                <button className='btn-left' onClick={prevStep}>Back</button>
                <button className='btn-right' onClick={nextStep}>Continue</button>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <label className='label-popup-seller'>City <sup>*</sup></label>
              <Select
                name="city"
                options={cities}
                value={cities.find((city) => city.value === formData.city)}
                onChange={handleCityChange}
              />
              <label className='label-popup-seller'>Radius (in km) <sup>*</sup></label>
              <input
                type="text"
                name="radius"
                placeholder="Enter radius"
                value={formData.radius}
                onChange={handleChange}
              />
              <label className='label-popup-seller'>Location type <sup>*</sup></label>
              <input
                type="text"
                name="locationtype"
                placeholder="Enter location type"
                value={formData.locationtype}
                onChange={handleChange}
              />
              <LoadScript googleMapsApiKey="AIzaSyBBi3lP1WS-C7AO4rYlYWKWpGnLq7mQu0Q">
                <GoogleMap
                  mapContainerStyle={{ height: '300px', width: '100%' }}
                  center={formData.markerPosition}
                  zoom={12}
                  onClick={handleMapClick}
                >
                  <Marker position={formData.markerPosition} />
                  {formData.radius && (
                    <Circle
                      center={formData.markerPosition}
                      radius={parseFloat(formData.radius) * 1000}
                      options={{
                        fillColor: 'rgba(136, 145, 241, 0.3)',
                        strokeColor: '#8891F1',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                  )}
                </GoogleMap>
              </LoadScript>
              <div className='row-popup'>
                <button className='btn-left' onClick={prevStep}>Back</button>
                <button className='btn-right' onClick={handleSubmit} disabled={uploading}>
                  {uploading ? 'Uploading...' : 'Submit'}
                </button>
              </div>
            </>
          )}
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default PopupSellar;
