import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect, useState } from 'react';
import { TimePicker, Modal, Checkbox } from 'antd';
import Switch from 'react-switch';
import moment from 'moment';
import './ServiceTiming.css';
import { CiSearch } from "react-icons/ci";

const serviceProvidersList = [
    { id: 1, Loc: 'B1, XYZ Mall Parking, Dubai , UAE', name: 'Quick Car wash', img: 'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg' },
    { id: 2, Loc: 'B1, XYZ Mall Parking, Dubai , UAE', name: 'DxB Car Wash', img: 'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg' },
    { id: 3, Loc: 'B1, XYZ Mall Parking, Dubai , UAE', name: 'Another Service', img: 'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg' },
    { id: 4, Loc: 'B1, XYZ Mall Parking, Dubai , UAE', name: 'Another Service', img: 'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg' },
    { id: 5, Loc: 'B1, XYZ Mall Parking, Dubai , UAE', name: 'Another Service', img: 'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg' },
    { id: 6, Loc: 'B1, XYZ Mall Parking, Dubai , UAE', name: 'Another Service', img: 'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg' },
    { id: 7, Loc: 'B1, XYZ Mall Parking, Dubai , UAE', name: 'Another Service', img: 'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg' }
];

const ServiceTiming = () => {
  const [isSpecificProvider, setIsSpecificProvider] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [timeSettings, setTimeSettings] = useState({
    Monday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Tuesday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Wednesday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Thursday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Friday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Saturday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
    Sunday: { start: moment("08:00", "h:mm a"), end: moment("08:00", "h:mm a"), active: false },
  });

  const handleToggle = (day) => {
    setTimeSettings({
      ...timeSettings,
      [day]: { ...timeSettings[day], active: !timeSettings[day].active }
    });
  };

  const handleTimeChange = (day, type, value) => {
    setTimeSettings({
      ...timeSettings,
      [day]: { ...timeSettings[day], [type]: value }
    });
  };

  const handleSelectProvider = (provider) => {
    setSelectedProviders((prev) =>
      prev.some(p => p.id === provider.id) ? prev.filter((p) => p.id !== provider.id) : [...prev, provider]
    );
  };

  const handleSwitchChange = () => {
    setIsSpecificProvider(!isSpecificProvider);
    if (!isSpecificProvider) {
      setSelectedProviders([]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("Selected Providers: ", selectedProviders.map(p => ({ id: p.id, name: p.name })));
    console.log("Time Settings: ", timeSettings);
  }, [selectedProviders, timeSettings]);

  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AdminSidebar />
      </div>
      <div className='div-Dashbaord'>
        <div className='width-settimg addtionwidth-setting'>
          <div className='receipt-row' style={{ alignItems: 'center' }}>
            <h1 className='h1-all-heading'>Service Timing </h1>
            <div className='receipt-row' style={{ gap: 20 }}>
              <p className='h5-class-top-class' style={{ fontSize: 16, fontWeight: 500 }}>Specific Service Provider</p>
              <Switch
                onChange={handleSwitchChange}
                checked={isSpecificProvider}
                onColor="#747EEF"
                offColor="#bcbcbc"
                handleDiameter={17}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={36}
              />
            </div>
          </div>
          <div className="dashboard">
            <div className="dashboard-customer-details" style={{ width: '100%' }}>
              <div className="receipt-row" style={{ paddingTop: 10 }}>
                <div style={{width:'75%'}}>
                  <p className='paragprh-customer-com'>Manage operating schedule for all service providers or specific one</p>
                  <span>Specific Service Providers: <span>{selectedProviders.map(p => `${p.name}`).join(', ')}</span></span>
                </div>
                <button
                  onClick={() => isSpecificProvider && setShowPopup(true)}
                  className={isSpecificProvider ? 'btnactive' : 'btninactive'}
                  disabled={!isSpecificProvider}
                >
                  Select Service Providers
                </button>
              </div>
              <div className="timing-table">
                {Object.keys(timeSettings).map((day) => (
                  <div key={day} className="day-row">
                    <span>{day}</span>
                    <div className='padding-right-property'>
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        value={timeSettings[day].start}
                        onChange={(value) => handleTimeChange(day, 'start', value)}
                        disabled={!timeSettings[day].active}
                      />
                      -
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        value={timeSettings[day].end}
                        onChange={(value) => handleTimeChange(day, 'end', value)}
                        disabled={!timeSettings[day].active}
                      />
                    </div>
                    <Switch
                      checked={timeSettings[day].active}
                      onChange={() => handleToggle(day)}
                      onColor="#747EEF"
                      offColor="#bcbcbc"
                      handleDiameter={17}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      Checkbox={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={36}
                    />
                  </div>
                ))}
              </div>
            </div>
            <Modal
              closable={false}
              visible={showPopup}
              onCancel={() => setShowPopup(false)}
              onOk={() => setShowPopup(false)}
            >
              <div className='input-feild-dic-icon' style={{ marginTop: '0px', border: '1px solid #B3B3B3', borderRadius: '10px',width:'100%'}}>
                <CiSearch className='icons-search-size' />
                <input type="text" placeholder='Search by service provider' className='input-feild-class-job-history' />
              </div>
              <div style={{ height: '22em', overflow: 'scroll' }}>
                {serviceProvidersList.map((provider) => (
                  <div className='tope-customer-com-main' style={{ display: 'flex', justifyContent: 'space-between', padding: '11px 9px' }} key={provider.id}>
                    <div className='second-row-customer'>
                      <img src={provider.img} className="image-pro-companies" />
                      <div className='top-custorm-width'>
                        <h5 className='h5-class-top-class'>{provider.name}</h5>
                        <p className='paragraph-text-p'>{provider.Loc}</p>
                      </div>
                    </div>
                    <Checkbox
                      checked={selectedProviders.some(p => p.id === provider.id)}
                      onChange={() => handleSelectProvider(provider)}
                    />
                  </div>
                ))}
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceTiming;
