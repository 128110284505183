// import React, { useState } from 'react';
// import Modal from 'react-bootstrap/Modal';
// import { RxCross1 } from "react-icons/rx";
// import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
// import './app.css';

// const PopupAgent = (props) => {
//   const [formData, setFormData] = useState({
//     username: '',
//     name: '',
//     password: '',
//     role: 'Select Role'
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = () => {
//     console.log(formData);
//     props.onHide();
//   };

//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header style={{ justifyContent: 'space-between', border: 'none' }}>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Add Washta Agent
//         </Modal.Title>
//         <RxCross1 onClick={props.onHide} style={{ cursor: 'pointer' }} />
//       </Modal.Header>
//       <Modal.Body>
//         <div>
//           <label className='label-popup-seller'>Username <sup>*</sup></label>
//           <input
//             className='input-feild-popupagnet'
//             type="text"
//             name="username"
//             placeholder="Username"
//             value={formData.username}
//             onChange={handleChange}
//           />
//           <label className='label-popup-seller' style={{ marginTop: 10 }}>Name <sup>*</sup></label>
//           <input
//             className='input-feild-popupagnet'
//             type="text"
//             name="name"
//             placeholder="Name"
//             value={formData.name}
//             onChange={handleChange}
//           />
//           <label className='label-popup-seller' style={{ marginTop: 10 }}>Password <sup>*</sup></label>
//           <input
//             className='input-feild-popupagnet'
//             type="password"
//             name="password"
//             placeholder="Password"
//             value={formData.password}
//             onChange={handleChange}
//           />
//           <label className='label-popup-seller' style={{ marginTop: 10 }}>Role <sup>*</sup></label>
//           <select
//             style={{ padding: '10px 0px' }}
//             name="role"
//             value={formData.role}
//             onChange={handleChange}
//             className="select"
//           >
//             <option value="Select Role">Select Role</option>
//             <option value="Agents">Agents</option>
    
//           </select>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <div className='row-div-admin-dashboard' style={{ width: '100%' }}>
//           <ButtonDahbaord handleclick={props.onHide} textButton="Cancel" height={35} width={'30%'} background='transparent' />
//           <ButtonDahbaord handleclick={handleSubmit} textButton="Create" height={35} width={'30%'} background='purple' />
//         </div>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default PopupAgent;

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, TextField, Select, MenuItem, Button } from '@mui/material';
import { IoCloseOutline } from 'react-icons/io5';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import './app.css';

const PopupAgent = ({ show, onHide }) => {
  const [formData, setFormData] = useState({
    username: '',
    name: '',
    password: '',
    role: 'Select Role'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = () => {
    console.log(formData);
    onHide();
  };

  return (
    <Dialog
      open={show}
      onClose={onHide}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Add Washta Agent
        <p className='paragph-heading'>Create a new account</p>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onHide}
          aria-label="close"
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <IoCloseOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
        <label className='label-popup-seller'>Username <sup>*</sup></label>
           <input
            className='input-feild-popupagnet'
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleChange}
          />
                 <label className='label-popup-seller' style={{ marginTop: 10 }}>Name <sup>*</sup></label>
           <input
            className='input-feild-popupagnet'
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
          />
          <label className='label-popup-seller' style={{ marginTop: 10 }}>Password <sup>*</sup></label>
          <input
            className='input-feild-popupagnet'
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
          />
          <label className='label-popup-seller' style={{ marginTop: 10 }}>Role <sup>*</sup></label>
          <select
            style={{ padding: '10px 0px' }}
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="select"
          >
            <option value="Select Role">Select Role</option>
            <option value="Agents">Agents</option>
    
          </select>
        </div>
      </DialogContent>
      <DialogActions>
        <div className='row-div-admin-dashboard' style={{ width: '100%' }}>
          <ButtonDahbaord handleclick={onHide} textButton="Cancel" height={35} width={'30%'} background='transparent' />
          <ButtonDahbaord handleclick={handleSubmit} textButton="Create" height={35} width={'30%'} background='purple' />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PopupAgent;
