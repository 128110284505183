import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord'
import React from 'react'
const AgentRemoveCom = ({img,name,username,date,role,click,}) => {
  return (
    <div className='tope-customer-com-main' style={{display:'flex',justifyContent:'space-between'}}>

       <div className='second-row-customer' style={{cursor:'pointer'}}>
        <img src={img} className='image-pro-companies' />
        <div className='top-custorm-width'>
          <h5 className='h5-class-top-class'>{name}</h5>
        </div>
      </div>    
      <div className='div-colm-order'>
  <p className='paragph-heading' style={{fontSize:11}}>Username</p>
  <p className='h5-class-top-class' style={{fontWeight:400}}>{username}</p>
  </div>
  <div className='div-colm-order'>
  <p className='paragph-heading' style={{fontSize:11}}>Joining Date</p>
  <p className='h5-class-top-class' style={{fontWeight:400}}>{date}</p>
  </div>
  <div className='div-colm-order'>
  <p className='paragph-heading' style={{fontSize:11}}>Role</p>
  <p className='h5-class-top-class' style={{fontWeight:400}}>{role}</p>
  </div>
  <div className='div-colm-order'>
  <ButtonDahbaord  handleclick={click} textButton="Remove" height={35} width={'100%'} background='purple' />

  </div>
    </div>
  )
}

export default AgentRemoveCom