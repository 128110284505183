import React, { useState, useEffect } from 'react';
import './Order.css';
import Locationicon from '../../assets/images/location.png';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';

const ActiveOrders = ({ Accpet, Complete, orderlocation, timer, status, billingStatus, Price, Refuse }) => {
  const [showButtons, setShowButtons] = useState(true);
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    if (status === 'inprocess') {
      setIsAccepted(true);
      setShowButtons(true); // Show buttons if inprocess
    } else if (status === 'completed') {
      setShowButtons(false);
    } else {
      setShowButtons(true); // Default to showing buttons for other statuses
    }
  }, [status]);

  const handleRefuse = () => {
    Refuse();
    setShowButtons(false);
  };

  const handleAccept = () => {
    Accpet();
    setIsAccepted(true);
  };

  const handleComplete = () => {
    Complete();
    setShowButtons(false);
  };

  return (
    <div className='order-componet'>
      <div className='row-invoice' style={{ justifyContent: 'flex-start', alignItems: "flex-start" }}>
        <div className='div-order-active' style={{width:'100%'}}>
          <img src={Locationicon} style={{ width: '1.3em', objectFit: 'contain' }} />
          <h5 className='h5-heaidng-order'> {orderlocation}</h5>
        </div>
        <div className='timer'>
          <p className='paragph-heading'>{timer}</p>
        </div>
      </div>
      <div className='row-invoice adding-left-padding'>
        <div className='div-colm-order'>
          <p className='paragph-heading'>Status</p>
          <p className='paragph-heading-text'>{status}</p>
        </div>
        <div className='div-colm-order'>
          <p className='paragph-heading'>Billing Status</p>
          <p className='paragph-heading-text'>{billingStatus}</p>
        </div>
        <div className='div-colm-order'>
          <p className='paragph-heading'>Price</p>
          <p className='paragph-heading-text'>{Price} AED</p>
        </div>
      </div>
      {showButtons ? (
        <div className='btn-both adding-margin'>
          {status === 'ongoing' && (
            <>
              <ButtonDahbaord handleclick={handleRefuse} textButton="Refuse" width={'100%'} height={35} />
              <ButtonDahbaord handleclick={handleAccept} textButton='Accept' background={'purple'} width={'100%'} height={35} />
            </>
          )}
          {(status === 'inprocess' || isAccepted) && (
           <ButtonDahbaord handleclick={handleComplete} textButton='Completed' background={'purple'} width={'100%'} height={35} />
          )}
        </div>
      ) : status === 'inprocess' ? (
        <ButtonDahbaord handleclick={handleComplete} textButton='Completed' background={'purple'} width={'100%'} height={35} />
      ) : null}
    </div>
  );
};

export default ActiveOrders;
