import React from 'react'
const TopcampaniesColm = ({MainName,OrderId,OrderDate,Cost,orderdatespan}) => {

    return (
    <div className='latestOrder' style={{padding:'10px 9px'}}>
       <div className='main-div-latest-order'>
       <div><h5  className='heading-h5-later'>{MainName}</h5></div>
       </div>
       <div className='main-div-latest-order' style={{marginTop:7,marginBottom:2}}>
       <div className='div-order-inner'>
       <p className='paragph-heading' style={{fontSize:9,paddingBottom:5}}>Processed Orders</p>
        <h5 className='heading-h5-later'>{OrderId}</h5>
        </div>
        <div className='div-order-inner'>
       <p className='paragph-heading' style={{fontSize:9,paddingBottom:5}}>Average Sales</p>
        <h5 className='heading-h5-later'>{OrderDate} {orderdatespan && ( <span style={{fontSize:9}}>AED</span>)}</h5>
        </div>
        <div className='div-order-inner'>
       <p className='paragph-heading'  style={{fontSize:9,paddingBottom:5,textOverflow: 'ellipsis'}}>Total Sales (30 days)</p>
        <h5 className='heading-h5-later' >{Cost} {orderdatespan && ( <span style={{fontSize:9}}>AED</span>)} </h5>
        </div>
       
       </div>
        </div>
  )
}

export default TopcampaniesColm