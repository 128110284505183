
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect } from 'react'
import { CiSearch } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import BuyerCom from './BuyerCom';

const CustomerServiceBuyer = () => {
   const data = [
    {id:1,name:'Quick Car wash',location:"B 102, 123 street, Dubai , UAE",service:'Tire Wash',date:"13/02/24",time:'15:35',reqid:'12312',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'},
    {id:2,name:'Quick Car washing',location:"B 102, 123 street, Dubai , UAE",service:'Tire Wash',date:"13/02/24",time:'15:35',reqid:'12312',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'},
    {id:3,name:'Quick Car wash  ',location:"B 102, 123 street, Dubai , UAE",service:'Tire Wash',date:"13/02/24",time:'15:35',reqid:'12312',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'},
    {id:4,name:'Quick Car wash',location:"B 102, 123 street, Dubai , UAE",service:'Tire Wash',date:"13/02/24",time:'15:35',reqid:'12312',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'},
    {id:5,name:'Quick Car wash',location:"B 102, 123 street, Dubai , UAE",service:'Tire Wash',date:"13/02/24",time:'15:35',reqid:'12312',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'},
    {id:6,name:'Quick Car wash',location:"B 102, 123 street, Dubai , UAE",service:'Tire Wash',date:"13/02/24",time:'15:35',reqid:'12312',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'}
   ]
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const navigator = useNavigate()
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>Customer Service - Buyer </h1>
    <div className="dashboard">
     <div className='styles-div addtionwidth-setting'>
      <div className="chart-container">      
      {data.map((item)=>(
        <div key={item.id}>
        <BuyerCom thirdId={item.reqid} thirdName="Request ID" time={item.time} date={item.date} img={item.img} name={item.name} loc={item.location} service={item.service}/>
        </div>
      ))}
      
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default CustomerServiceBuyer