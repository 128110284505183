
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AgentRemoveCom from './AgentRemovecom';
import PopupAgent from './popupAgent';

const AdminAgent = () => {
  const [modalShow, setModalShow] = React.useState(false);
    const [recipient, setRecipient] = useState('');
    const [message, setMessage] = useState('');
    const handleSendNotification = () => {
        console.log('Selected Recipient:', recipient);
        console.log('Message:', message);
        setRecipient('')
        setMessage('')
      };
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const navigator = useNavigate()
      const data = [
        {id:1,name:'Quick Car wash',username:'stacy.kimbler',date:"13/02/24",role:'Agent',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'},
        {id:2,name:'Quick Car washing',username:'stacy.kimbler',date:"13/02/24",role:'Agent',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'},
        {id:3,name:'Quick Car wash  ',username:'stacy.kimbler',date:"13/02/24",role:'Agent',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'},
        {id:4,name:'Quick Car wash',username:'stacy.kimbler',date:"13/02/24",role:'Agent',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'},
        {id:5,name:'Quick Car wash',username:'stacy.kimbler',date:"13/02/24",role:'Agent',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'},
        {id:6,name:'Quick Car wash',username:'stacy.kimbler',date:"13/02/24",role:'Agent',img:'https://ameauto.com.au/wp-content/uploads/2020/02/carwash.jpg'}
       ]
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
        <div className='width-settimg'>

        <div class="receipt-row"> 
        <h1 className='h1-all-heading'>Agents </h1>
        <ButtonDahbaord  handleclick={() => setModalShow(true)} textButton="Add Agent" height={35} width={'16%'} background='purple' />
        </div>
        <PopupAgent
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
        <div className="chart-container">      
        {data.map((item)=>(
        <div key={item.id}>
        <AgentRemoveCom role={item.role} username={item.username}  time={item.time} date={item.date} img={item.img} name={item.name} />
        </div>
      ))}
      
      
      </div>

        </div>
   
    
    </div>
    </div>
  )
}

export default AdminAgent