import React, { useState } from 'react'
import Imgaaa from '../../../assets/images/profileimagescreen.png';
import { BsThreeDotsVertical } from "react-icons/bs";
import { Popover } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AllCustomerCom = ({name,date,status,payment,item,Imaag,onclick}) => {
    const [anchorEl, setAnchorEl] = useState(null);
const navigator = useNavigate()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className='main-div-all-cutomers-com'>
        <div className='colm-div-customer-com'>
        <div style={{display:'flex',gap:'5px'}}>
             <img src={Imaag||Imgaaa} style={{width:'3em',height:'3em',objectFit:'cover',borderRadius:5}}/>
              <div className='top-custorm-width'>
              <h5 className='h5-class-top-class'>{name}</h5>
          <p className='paragprh-customer-com'>{date}</p>
              </div>
             </div>
        </div>
        <div className='colm-div-customer-com'>
        <h5 className='paragraph-text-p'>Status</h5>
        <h5 className='h5-class-top-class'>{status}</h5>
        </div>
        <div className='colm-div-customer-com'>
        <h5 className='paragraph-text-p'>Total Spent</h5>
        <h5 className='h5-class-top-class'>{payment} <span style={{ fontSize: 9 }}>AED</span></h5>
        <BsThreeDotsVertical onClick={handleClick} className='threedor-icon forcefully-threedot' />
        <Popover className='details-popup' 
  id={id}
  open={open}
  anchorEl={anchorEl}
  onClose={handleClose}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}
>
  <p className='paragraph-text-p' style={{textDecoration:'underline'}} onClick={onclick}>View details</p>
</Popover>
        </div>
    </div>
  )
}

export default AllCustomerCom