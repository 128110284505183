import AllCustomerCom from 'admin/components/jobhistory/AllCustomerCom';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const AllCustomers = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const navigator = useNavigate()
      const data = [
       {
        id:1,
        name:'henry',
        time:'10:00 AM',
        status:'Pending',
        totalSpent:'2500',
        numberPlate:'BFG-123',
        location:'Dubai, UAE',
        email:'johndoe@example.com',
        phoneNum:'+971-32-5671839',
        car:[
            {
            id:1,
            carName:'FordRapter',
            type:'Truck',
            platenum:'FR-5677'
        },
        {
            id:2,
            carName:'FordRapter',
            type:'Sedan',
            platenum:'RR-5677'
        },
        {
            id:3,
            carName:'Ford',
            type:'Truck',
            platenum:'GR-5677'
        }
        ],
        Booking:[
            {
            id:1,
            bookingId:'# A-1234',
            date:'16 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'100',
        },
        {
            id:2,
            bookingId:'# A-2214',
            date:'17 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'120',
        },
        {
            id:3,
            bookingId:'# A-1234',
            date:'12 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'140',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        
        ]
    },
    {
        id:2,
        numberPlate:'FFG-223',
        name:'Kristin Watson',
        time:'10:00 AM',
        status:'Completed',
        totalSpent:'1500',
        location:'Dubai, UAE',
        email:'example@example.com',
        phoneNum:'+911-32-5671839',
        car:[
            {
            id:1,
            carName:'FordRapter',
            type:'Truck',
            platenum:'FR-5677'
        },
        {
            id:2,
            carName:'FordRapter',
            type:'Sedan',
            platenum:'RR-5677'
        },
        {
            id:3,
            carName:'Ford',
            type:'Truck',
            platenum:'GR-5677'
        }
        ],
        Booking:[
            {
            id:1,
            bookingId:'# A-1234',
            date:'16 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'100',
        },
        {
            id:2,
            bookingId:'# A-2214',
            date:'17 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'120',
        },
        {
            id:3,
            bookingId:'# A-1234',
            date:'12 May 2024 At 10:00 AM  ',
            status:'completed',
            totalspent:'140',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        {
            id:4,
            bookingId:'# D-2214',
            date:'12 May 2024 At 10:00 AM  ',
            status:'Pending',
            totalspent:'1400',
        },
        
        ]
    }
      ]
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>All Customers </h1>
    <div className="dashboard">    
      <div className='styles-div addtionwidth-setting '>
        {data.map((item)=>(
            <div key={item.id}>
                <AllCustomerCom
                item={item}
                onclick={()=> navigator('/super-admin-dashboard/customers/all-customers/customer-details',{ state: { item: item } })}
                 status={item.status} 
                 name={item.name}
                payment={item.totalSpent}
                date={item.time}/>
            </div>

        ))}
   
      
      </div>
    </div>
    </div>
    </div>
  )
}

export default AllCustomers