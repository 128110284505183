import React, { useEffect, useState } from 'react';
import './app.css';
import ClipBord from '../../../assets/images/Clipboard.png'
import LatestOrder from 'components/latestOrder/LatestOrder';
import axios from 'axios';
import { formatDate} from 'pages/utils/formdatedynamics';
function Orders() {
  const [loading,setLoading] = useState()
  const [data,setData] =  useState([])
  const Data =[
    {id:1,
    name:'Jhon Doe',
    OrderId:'CS-1095',
    OrderDate:'02 Feb',
    Cost:'AED 20',
    status:'Ongoing'
  },
  {id:2,
    name:'Harry Doe',
    OrderId:'AS-1905',
    OrderDate:'20 Mar',
    Cost:'AEE 30',
    status:'Completed'
  },
  {id:3,
    name:'Jhon Doe',
    OrderId:'SS-1095',
    OrderDate:'02 Feb',
    Cost:'EED 20',
    status:'Completed'
  },
  {id:4,
    name:'Harry',
    OrderId:'SS-0095',
    OrderDate:'02 Feb',
    Cost:'ADD 40',
    status:'Completed'
  }
]
const fetchData = async () => {
  const token = localStorage.getItem('accessToken'); // Retrieve token from local storage
  const apiUrl = 'https://washta-9006f93279b8.herokuapp.com/api/seller/ActiveOrder';

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("responseactive order", response.data.data);
    setData(response.data.data);
  
  } catch (err) {
    console.log(err.message);
    setLoading(true);
  } finally {
    setLoading(false);
  }
};
useEffect(()=>{
  fetchData()
},[])
  return (
    <div className="orders">
      <div className='chart-colm-div-heading'> 
          <img src={ClipBord} style={{width:'15%',objectFit:'contain'}}/>
          <h2 className='main-heading2'>Total Orders</h2>
          </div>
      <div className="order-list">
        {data?.map((data) => {
          console.log(data)
          return(
          <div key={data.id} style={{marginTop:20}}>
            <LatestOrder Cost={data.cost} OrderDate={formatDate(data?.date)}  OrderId={data._id.slice(0,5)}status={data.status} MainName={data.name} />
            </div>
            )
           
        })}
      </div>
    </div>
  );
}

export default Orders;
