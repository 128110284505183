import AdminSidebar from 'admin/sidebar/AdminSidebar'
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord'
import React from 'react'
import { MdDeleteOutline } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '../../../../assets/images/deleteIcon.png'
import ReviewSummary from 'pages/clientDashbaord/dashboard/ratingandReviews/ReviewSummary';
import DonutChart from './DonutChart';
import TotalSalesChart from 'admin/components/dashboardcom/TotalSaleschart';
import { Rating } from '@mui/material';
import { MdOutlineReply } from 'react-icons/md';
import './app.css'
const DetailsCompaniesCom = () => {
    const location = useLocation();
    const { item } = location.state || {};
    const navigation = useNavigate()
    const averageRating = 4.5;
      const totalReviews = 273;
      const ratings = {
        1: 150,
        2: 70,
        3: 30,
        4: 15,
        5: 8,
      };
      const recommendationPercentage = 88;
console.log(item)
const reviews = [
    {
      id: 1,
      name: 'Kristin Watson',
      date: '16 May 2024 At 10:00 AM',
      review: 'If you\'re looking to give your vehicle the royal treatment, look no further than Sparkle Clean Car Wash. Nestled conveniently on Main Street, this car wash establishment exceeded all my expectations.',
      rating: 4,
    },
    {
      id: 2,
      name: 'Kristin Watson',
      date: '16 May 2024 At 10:00 AM',
      review: 'If you\'re looking to give your vehicle the royal treatment, look no further than Sparkle Clean Car Wash. Nestled conveniently on Main Street, this car wash establishment exceeded all my expectations.',
      rating: 1.4,
    },
    {
      id: 3,
      name: 'Kristin Watson',
      date: '16 May 2024 At 10:00 AM',
      review: 'If you\'re looking to give your vehicle the royal treatment, look no further than Sparkle Clean Car Wash. Nestled conveniently on Main Street, this car wash establishment exceeded all my expectations.',
      rating: 2.3,
    },
    {
      id: 4,
      name: 'Kristin Watson',
      date: '16 May 2024 At 10:00 AM',
      review: 'If you\'re looking to give your vehicle the royal treatment, look no further than Sparkle Clean Car Wash. Nestled conveniently on Main Street, this car wash establishment exceeded all my expectations.',
      rating: 2,
    },
  ];
 return (
<div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'78%'}} >
        <h1 className='h1-all-heading' style={{width:'30%'}}>{item.name}</h1>
        <div style={{display:'flex',width:'70%',justifyContent:'end',gap:10}}>
        <ButtonDahbaord background={'purple'} padding={'5px 2px'} handleclick='' textButton='Block Account' height={35} width={'20%'} />
        <ButtonDahbaord background={'purple'} padding={'5px 2px'} handleclick='' textButton='Terminate Seller' height={35} width={'25%'} />
        </div>
        </div>
   
    <div className="dashboard-customer-details">
    <h2>Details</h2>    
      <div className='div-customer-details-main'> 
            
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Location:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.location} readOnly />
            </div>
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Covered Area Radius:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.raduis} readOnly />
            </div>
            
          
      </div>
    </div>

    <div className="dashboard-customer-details">
    <h2>Service</h2>    
      <div className='div-customer-details-main'> 
      <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Service:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.service} readOnly />
            </div>
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Price:</p>
              <input className='valt-number-input-feild' placeholder='text' value={"AED" + item?.price} readOnly />
            </div>
      </div>
    </div>

    <div className="dashboard-customer-details">
     
    <div style={{width:'100%'}} >
    <h2>Statistics</h2> 
    <div className='destils-row-div'>
   <div className='colm-statis-detsils-company'>
   <DonutChart/>
    </div> 
    <div className='colm-statis-detsils-company'>
    <ReviewSummary boxShadow={'none'} border={'0.5px solid #747eef '} margin={0} color={"#f7f7f7"} text="Customer Ratings"  ratings={ratings}
        averageRating={averageRating}
        totalReviews={totalReviews}
        recommendationPercentage={recommendationPercentage} />
        </div>
        </div> 
        <TotalSalesChart/>
    </div>
    
    </div>

    <div className="dashboard-customer-details">
    <h2>Reviews</h2>    
    {reviews.map((review) => (
        <div key={review.id} className="review-box">
         <div className='div-rewie-main'>
           <h5 className='heading-h5-later'>{review.name}</h5>
           <Rating name="half-rating-read" defaultValue={review?.rating} precision={0.5} sx={{fontSize:20,color:'#F39D00'}} readOnly />
         </div>
          <p className='paragph-heading'>{review.review}</p>
          
          <div className='div-rewie-main'>
          <p className='paragph-heading addtion-adding'>{review.date}</p>
         <div className='replyicons'>
         <MdDeleteOutline style={{fontSize:23}} />
         <button className='replybtn'>  Delete</button>
         </div>
         <div className='replyicons' style={{paddingLeft:20}}>
         <MdOutlineReply style={{fontSize:23}} />
         <button className='replybtn' >  Reply</button>
         </div>
          
          </div>
        </div>
      ))}
    </div>

    </div>
    </div>
  )
}

export default DetailsCompaniesCom