import React from 'react'
const BuyerCom = ({img,name,loc,service,date,time,thirdName,thirdId,}) => {
  return (
    <div className='tope-customer-com-main' style={{display:'flex',justifyContent:'space-between'}}>

       <div className='second-row-customer' style={{cursor:'pointer'}}>
        <img src={img} className='image-pro-companies' />
        <div className='top-custorm-width'>
          <h5 className='h5-class-top-class'>{name}</h5>
          <p className='paragprh-customer-com'>{loc}</p>
        </div>
      </div>    
      <div className='div-colm-order'>
  <p className='paragph-heading' style={{fontSize:11}}>Service</p>
  <p className='h5-class-top-class' style={{fontWeight:400}}>{service}</p>
  </div>
  <div className='div-colm-order'>
  <p className='paragph-heading' style={{fontSize:11}}>Date</p>
  <p className='h5-class-top-class' style={{fontWeight:400}}>{date}</p>
  </div>
  <div className='div-colm-order'>
  <p className='paragph-heading' style={{fontSize:11}}>Time</p>
  <p className='h5-class-top-class' style={{fontWeight:400}}>{time}</p>
  </div>
  <div className='div-colm-order'>
  <p className='paragph-heading' style={{fontSize:11}}>{thirdName}</p>
  <p className='h5-class-top-class' style={{fontWeight:400}}>{thirdId}</p>
  </div>
    </div>
  )
}

export default BuyerCom