import AdminSidebar from 'admin/sidebar/AdminSidebar'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import DeleteIcon from '../../../assets/images/deleteIcon.png'
const ViewAllBooking = () => {
    const location = useLocation();
    const { item } = location.state || {};
    console.log(item)
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord width' >
    <h1 className='h1-all-heading'>{item?.name} </h1>
    <div className="dashboard">
    <div className="dashboard-customer-details">
    <h2>Job Bookings</h2> 
    <div className='div-customer-details-main' style={{gridTemplateColumns:'repeat(auto-fill, minmax(100%, 1fr))'}}>
    {item?.Booking?.slice(0, 6).map((item)=>(
        <div className='receipt-footer' key={item?.id} style={{gap:12}}>
        <div  className='booking-div-customer-div paddingRight' style={{display:'flex',width:'100%',justifyContent:'space-between',marginTop:0}}>
             <div>
              <h5 className='h5-class-top-class'>{item?.bookingId}</h5>
              <p className='paragprh-customer-com'>{item?.date}</p>
              </div>
              <div>
              <h5 className='paragraph-text-p'>Status</h5>
        <h5 className='h5-class-top-class'>{item.status}</h5>
              </div>
              <div>
              <h5 className='paragraph-text-p'>Total Spent</h5>
        <h5 className='h5-class-top-class'>{item.totalspent} <span style={{ fontSize: 9 }}>AED</span></h5>
              </div>
              
              </div>
              <div className='view-delete-btn'>
                 <img src={DeleteIcon} className='image-delete-view-details'/>
              </div>
              </div>
      ))} 
    </div>
    </div>
      </div>
    </div>
    </div>
    
  )
}

export default ViewAllBooking