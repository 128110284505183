import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import DELETEICOn from '../../../assets/images/deleteIcon.png'
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import LOGO from '../../../assets/images/app.logo.jpeg'
import { CiSearch } from "react-icons/ci";
import './app.css'

const AllCustomersAppRe = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
      const apiUrl = 'https://washta-9006f93279b8.herokuapp.com/api/admin/customer'; // Replace with your API endpoint

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data.reverse());

        setLoading(false); // Set loading to false after data is fetched
      } catch (err) {
        console.log(err.message);
        // Set loading to false in case of an error
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredData = data.filter(item =>
    item.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AdminSidebar />
      </div>
      <div className='div-Dashbaord'>
        <div className='div-row-all-customer'>
          <h1 className='h1-all-heading'>All Customers</h1>
          <div className='input-feild-dic-icon all-cutomerssearch'>
            <CiSearch className='icon-serach-box-input' />
            <input
              placeholder='Search by username or email'
              className='input-feild-class-job-history'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="dashboard">
          <div className='styles-div' style={{ width: '100%' }}>
            {loading ? (
              Array.from(new Array(10)).map((_, index) => (
                <div key={index} style={{ background: '#747eef9c', padding: '10px 20px', display: 'flex', alignItems: 'center', borderRadius: '8px', border: '1px solid #ddd', marginBottom: '10px', marginTop: 20 }}>
                  <Skeleton variant="rectangular" width={48} height={53} style={{ marginRight: '10px', borderRadius: '8px', background: '#747eef9c' }} />
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                </div>
              ))
            ) : (
              filteredData.map((item) => (
                <div className='main-invoice-div' style={{ marginTop: 15 }} key={item.id}>
                  <div className='row-invoice'>
                    <div className='row-invoice' style={{ justifyContent: 'flex-start' }}>
                      <img src={LOGO} style={{ width: '5%', boxShadow: '0px 0px 3px 0px #333', borderRadius: '15%' }} />
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Username</h5>
                        <p className='paragph-heading'>{item.username}</p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Email</h5>
                        <p className='paragph-heading'>{item.email}</p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Phone</h5>
                        <p className='paragph-heading'>{item.phone}</p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Date</h5>
                        <p className='paragph-heading'>20 July At 19:20:AM</p>
                      </div>
                      <div><img style={{ width: 20 }} src={DELETEICOn} alt="Delete Icon" /></div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCustomersAppRe;
