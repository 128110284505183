import Sidebar from 'pages/sideBar/SideBar';
import React, { useEffect, useState } from 'react';
import '../app.css';
import imgReviews from '../../../../assets/images/order.png';
import ActiveOrders from 'components/orderComponets/ActiveOrders';
import PastOrdersIMg from '../../../../assets/images/pastorder.png';
import PastOrderCom from 'components/orderComponets/PastOrders';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import {formatDateconvert}  from '../../../utils/formdatedynamics';
const apiURL = 'https://washta-9006f93279b8.herokuapp.com/api/seller/pastorder';

function OrderDashbaord() {
  const [data, setData] = useState([]);
  const [pastorderdata, setpastorderdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const token = localStorage.getItem('accessToken'); // Retrieve token from local storage
    const apiUrl = 'https://washta-9006f93279b8.herokuapp.com/api/seller/order';

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("responseactive order", response.data);
      setData(response.data.data);
    
    } catch (err) {
      console.log(err.message);
      setLoading(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchpastorder = async () => {
    const token = localStorage.getItem('accessToken'); // Retrieve token from local storage

    try {
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("setpastorderdata order", response.data.data);
      setpastorderdata(response.data.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    fetchpastorder();
  }, []);

  const Refusehandle = async (id) => {
    const token = localStorage.getItem('accessToken');
    const url = `https://washta-9006f93279b8.herokuapp.com/api/seller/orderStatus/${id}`;
    const status = 'cancelled'; // or 'completed', replace with the actual status you need
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
  
    const body = {
      id: id,
      status: status,
    };
  
    try {
      const response = await axios.patch(url, body, { headers });
      window.location.reload()
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const accpethandle = async (id) => {
    const token = localStorage.getItem('accessToken');
    const url = `https://washta-9006f93279b8.herokuapp.com/api/seller/orderStatus/${id}`;
    const status = 'inprocess';
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
  
    const body = {
      id: id,
      status: status,
    };
  
    try {
      const response = await axios.patch(url, body, { headers });
      window.location.reload()
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const Completehandle = async (id) => {
    const token = localStorage.getItem('accessToken');
    const url = `https://washta-9006f93279b8.herokuapp.com/api/seller/orderStatus/${id}`;
    const status = 'completed';
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
  
    const body = {
      id: id,
      status: status,
    };
  
    try {
      const response = await axios.patch(url, body, { headers });
      window.location.reload()
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <Sidebar />
      </div>
      <div className='div-Dashbaord'>
        <h1 className='h1-all-heading'>Orders</h1>
        <div className="dashboard">
          <div className='styles-div-order'>
            <div className='invoice-border'>
              <div className='chart-colm-div-heading'>
                <img src={imgReviews} style={{ width: '1.5em', objectFit: 'contain' }} />
                <h2 className='main-heading2'>Active Orders</h2>
              </div>
              <div className='row-details-order'>
                {loading ? (
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    {Array.from(new Array(3)).map((_, index) => (
                      <Skeleton variant="rectangular" width={300} height={150} key={index} sx={{ borderRadius: 3 }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 2 }}>
                          <Skeleton variant="rectangular" width={300} height={30} sx={{ borderRadius: 1 }} style={{ background: '#f1f2fd' }} />
                          <Skeleton variant="rectangular" width={300} height={30} sx={{ borderRadius: 1 }} style={{ background: '#f1f2fd' }} />
                        </Box>
                      </Skeleton>
                    ))}
                  </Box>
                ) : (
                  data.map((item) => (
                    <div key={item._id} style={{ flex: '1 2 calc(32% - 16px)' }}>
                      <ActiveOrders
                        Accpet={() => accpethandle(item._id)}
                        Refuse={() => Refusehandle(item._id)}
                        Complete={() => Completehandle(item._id)}
                        Price={item?.cost}
                        billingStatus={item.billingStatus}
                        status={item.status}
                        orderlocation={item?.location?.text|| '3394, Sheikh Ave, JBR'}
                        timer={item.timer}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className='invoice-border'>
              <div className='chart-colm-div-heading'>
                <img src={PastOrdersIMg} style={{ width: '1.5em', objectFit: 'contain' }} />
                <h2 className='main-heading2'>Past Orders</h2>
              </div>
              <div className='row-details-order'>
                {loading ? (
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    {Array.from(new Array(3)).map((_, index) => (
                      <Skeleton variant="rectangular" width={300} height={150} key={index} sx={{ borderRadius: 3 }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 2 }}>
                          <Skeleton variant="rectangular" width={300} height={30} sx={{ borderRadius: 1 }} style={{ background: '#f1f2fd' }} />
                          <Skeleton variant="rectangular" width={300} height={30} sx={{ borderRadius: 1 }} style={{ background: '#f1f2fd' }} />
                        </Box>
                      </Skeleton>
                    ))}
                  </Box>
                // ) : (
                // {pastorderdata?.map((item) => (
                //   <div key={item.id} style={{ flex: '1 1 calc(33.33% - 16px)' }}>
                //     <PastOrderCom Numberplate={item?.vehicleId?.vehiclePlateNumber} Cartype={item?.vehicleId?.vehicleType} Distance={formatDateconvert(item?.orderCompleteAt)} orderlocation={item.location?.text} timer={item.timer} />
                //   </div>
                //   ))
                //   )}
               ) : (
                pastorderdata?.map((item) => (
                    <div key={item._id} style={{ flex: '1 1 calc(33% - 16px)' }}>
                     <PastOrderCom
                      Numberplate={item?.vehicleId?.vehiclePlateNumber}
                       Cartype={item?.vehicleId?.vehicleType} 
                       Distance={formatDateconvert(item?.orderCompleteAt)}
                        orderlocation={item.location?.text}
                         timer={item.timer} />
                      {/* <ActiveOrders
                        Accpet={() => accpethandle(item._id)}
                        Refuse={() => Refusehandle(item._id)}
                        Complete={() => Completehandle(item._id)}
                        Price={item?.cost}
                        billingStatus={item.billingStatus}
                        status={item.status}
                        orderlocation={item?.location?.text|| '3394, Sheikh Ave, JBR'}
                        timer={item.timer}
                      /> */}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDashbaord;
