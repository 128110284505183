// import React, { useEffect, useState } from 'react';
// import Sidebar from 'pages/sideBar/SideBar';
// import '../app.css';
// import './Rating.css';
// import imgReviews from '../../../../assets/images/latestreviews.png';
// import { Rating, Box, Skeleton, IconButton } from '@mui/material';
// import { MdOutlineReply } from "react-icons/md";
// import axios from 'axios';
// import { formatDateconvert } from 'pages/utils/formdatedynamics';
// import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
// import { useNavigate } from 'react-router-dom';
// import ReviewSummary from './ReviewSummary';

// function RatingAndReviews() {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const navigation = useNavigate();
//   const ratings = {
//             1: 150,
//             2: 70,
//             3: 30,
//             4: 15,
//             5: 8,
//           };
//           const averageRating = 4.5;
//       const totalReviews = 273;
//       const recommendationPercentage = 88;

//   const fetchData = async () => {
//     const token = localStorage.getItem('accessToken');
//     const apiUrl = 'https://washta-9006f93279b8.herokuapp.com/api/seller/shopReview';

//     try {
//       const response = await axios.get(apiUrl, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       console.log("response reviews", response.data.data);
//       setData(response.data.data.reverse());
//     } catch (err) {
//       console.log(err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     fetchData();
//   }, []);

//   const formatTime = (isoString) => {
//     const date = new Date(isoString);
//     return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//   };

//   return (
//     <div className='mainDiv-all-sideBar'>
//       <div className='div-sidebar'>
//         <Sidebar />
//       </div>
//       <div className='div-Dashbaord'>
//         <h1 className='h1-all-heading'>Rating & Reviews</h1>
//         <div className="dashboard">
//           <div className='styles-div'>
//             <div className='invoice-border'>
//               <div className='chart-colm-div-heading' style={{ width: '100%', justifyContent: 'space-between' }}>
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                   <img src={imgReviews} style={{ width: '1.4em', objectFit: 'contain' }} alt="Latest Reviews" />
//                   <h2 className='main-heading2'>Latest Reviews</h2>
//                 </div>
//                 <ButtonDahbaord handleclick={() => navigation('/become-a-seller/rating-and-reviews/all-rating-reviews')} textButton="View All" height={29} width={115} background={'purple'} />
//               </div>
//               {loading ? (
//                 <Box sx={{ width: '100%', marginTop:1}}>
//                   {[1, 2, 3].map((index) => (
//                     <Box key={index} sx={{ marginBottom: 2,background:'#747eef9c',borderRadius:4,paddingTop:1,paddingBottom:1}}>
//                       <Skeleton variant="text" width="20%" height={20} sx={{ marginLeft:2,background:'#f1f2fd',borderRadius:1}} />
//                       <Skeleton variant="rectangular" width="96%" height={40} sx={{ marginBottom: 1 ,marginLeft:2,background:'#f1f2fd',borderRadius:1}} />
//                       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginTop:'-6px',paddingLeft:2,paddingRight:2, }}>
//                         <Skeleton variant="text" width="30%" height={20} style={{background:'#f1f2fd'}} />
//                         <Skeleton variant="rectangular" width={80} height={13} style={{marginTop:1,background:'#f1f2fd',borderRadius:2}} />
//                       </Box>
//                     </Box>
//                   ))}
//                 </Box>
//               ) : (
//                 data.map((review) => (
//                   <div key={review.id} className="review-box">
//                     <div className='div-rewie-main'>
//                       <h5 className='heading-h5-later'>{review.customerId?.username || 'Kristin Watson'}</h5>
//                       <Rating name="half-rating-read" value={review.rating} precision={0.5} sx={{ fontSize: 20, color: '#F39D00' }} readOnly />
//                     </div>
//                     <p className='paragph-heading'>{review.comment.text}</p>
//                     <div className='div-rewie-main'>
//                       <p className='paragph-heading addtion-adding'>{formatDateconvert(review.date) + ' At ' + formatTime(review.date)} </p>
//                       <div className='replyicons'>
//                         <IconButton size="small">
//                           <MdOutlineReply />
//                         </IconButton>
//                         <button className='replybtn'>Reply</button>
//                       </div>
//                     </div>
//                   </div>
//                 ))
//               )}
//             </div>
//           </div>
//           <div className='styles-div-text' >
//         <ReviewSummary text="Review Summary"  ratings={ratings}
//         averageRating={averageRating}
//         totalReviews={totalReviews}
//         recommendationPercentage={recommendationPercentage} />
//       </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default RatingAndReviews;
import React, { useEffect, useState } from 'react';
import Sidebar from 'pages/sideBar/SideBar';
import '../app.css';
import './Rating.css';
import imgReviews from '../../../../assets/images/latestreviews.png';
import { Rating, Box, Skeleton, IconButton, TextField, Button } from '@mui/material';
import { MdOutlineReply, MdEdit, MdDelete } from "react-icons/md";
import axios from 'axios';
import { formatDateconvert } from 'pages/utils/formdatedynamics';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import { useNavigate } from 'react-router-dom';

function RatingAndReviews() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [replyText, setReplyText] = useState('');
  const [currentReviewId, setCurrentReviewId] = useState(null);
  const [editing, setEditing] = useState({ reviewId: null, replyId: null, isReview: false });
  const [text, setText] = useState('');
  const [showReplies, setShowReplies] = useState({});
  const navigate = useNavigate();

  const fetchData = async () => {
    const token = localStorage.getItem('accessToken');
    const apiUrl = 'https://washta-9006f93279b8.herokuapp.com/api/seller/shopReview';

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response reviews", response.data);
      setData(response.data.data.reverse());
    } catch (err) {
      console.error('Error reponse data:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handleReplyClick = (reviewId) => {
    setReplyText('');
    setCurrentReviewId(reviewId);
  };

  const handleReplyChange = (e) => {
    setReplyText(e.target.value);
  };

  const handlePostReply = async () => {
    const token = localStorage.getItem('accessToken');
    const replyTextValue = replyText.trim();

    if (!replyTextValue || !currentReviewId) {
      console.error('Reply text or review ID is missing.');
      return;
    }

    const payload = {
      comment: {
        text: replyTextValue,
      },
      reviewId: currentReviewId,
    };

    try {
      const response = await axios.post('https://washta-9006f93279b8.herokuapp.com/api/seller/replyReview', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Reply posted successfully', response.data);
      fetchData();
    } catch (err) {
      console.error('Error posting reply:', err.response ? err.response.data : err.message);
    } finally {
      setReplyText('');
      setCurrentReviewId(null);
    }
  };

  const handleEdit = (reviewId, replyId, replyText, isReview) => {
    setEditing({ reviewId, replyId, isReview });
    setText(replyText);
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem('accessToken');
    const apiUrl = editing.isReview
      ? `https://washta-9006f93279b8.herokuapp.com/api/seller/editReview/${editing.reviewId}`
      : `https://washta-9006f93279b8.herokuapp.com/api/seller/editReply/${editing.replyId}`;

    try {
      const response = await axios.patch(apiUrl, { text }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Update successful', response.data);
      fetchData();
      setEditing({ reviewId: null, replyId: null, isReview: false });
      setText('');
    } catch (err) {
      console.error('Error updating:', err.message);
    }
  };

  const handleDelete = async (reviewId, replyId, isReview) => {
    const token = localStorage.getItem('accessToken');
    const apiUrl = isReview
      ? `https://washta-9006f93279b8.herokuapp.com/api/seller/deleteReview/${reviewId}`
      : `https://washta-9006f93279b8.herokuapp.com/api/seller/deleteReply/${replyId}`;

    try {
      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Delete successful', response.data);
      fetchData();
    } catch (err) {
      console.error('Error deleting:', err.message);
    }
  };

  const handleShowReplies = (reviewId) => {
    setShowReplies((prev) => ({ ...prev, [reviewId]: !prev[reviewId] }));
  };

  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <Sidebar />
      </div>
      <div className='div-Dashbaord'>
        <h1 className='h1-all-heading'>Rating & Reviews</h1>
        <div className="dashboard">
          <div className='styles-div'>
            <div className='invoice-border'>
              <div className='chart-colm-div-heading' style={{ width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={imgReviews} style={{ width: '1.4em', objectFit: 'contain' }} alt="Latest Reviews" />
                  <h2 className='main-heading2'>Latest Reviews</h2>
                </div>
                <ButtonDahbaord handleclick={() => navigate('/become-a-seller/rating-and-reviews/all-rating-reviews')} textButton="View All" height={29} width={115} background={'purple'} />
              </div>
              {loading ? (
                <Box sx={{ width: '100%', marginTop: 1 }}>
                  {[1, 2, 3].map((index) => (
                    <Box key={index} sx={{ marginBottom: 2, background: '#747eef9c', borderRadius: 4, paddingTop: 1, paddingBottom: 1 }}>
                      <Skeleton variant="text" width="20%" height={20} sx={{ marginLeft: 2, background: '#f1f2fd', borderRadius: 1 }} />
                      <Skeleton variant="rectangular" width="96%" height={40} sx={{ marginBottom: 1, marginLeft: 2, background: '#f1f2fd', borderRadius: 1 }} />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-6px', paddingLeft: 2, paddingRight: 2 }}>
                        <Skeleton variant="text" width="30%" height={20} style={{ background: '#f1f2fd' }} />
                        <Skeleton variant="rectangular" width={80} height={13} style={{ marginTop: 1, background: '#f1f2fd', borderRadius: 2 }} />
                      </Box>
                    </Box>
                  ))}
                </Box>
              ) : (
                data.map((review) => (
                  <div key={review._id} className="review-box">
                    <div className='div-rewie-main'>
                      <h5 className='heading-h5-later'>{review.customerId?.username || 'Anonymous'}</h5>
                      <Rating name="half-rating-read" value={review.rating || 0} precision={0.5} sx={{ fontSize: 20, color: '#F39D00' }} readOnly />
                    </div>
                    {editing.reviewId === review._id ? (
                      <div>
                        <TextField
                          fullWidth
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                          multiline
                        />
                        <Button onClick={handleUpdate} variant="contained" color="primary">Update Review</Button>
                      </div>
                    ) : (
                      <p className='paragph-heading'>{review.comment?.text || 'No comment available'}</p>
                    )}
                    <div className='div-rewie-main'>
                      <p className='paragph-heading addtion-adding'>{formatDateconvert(review.date) + ' At ' + formatTime(review.date)} </p>
                      <div className='reply-buttons'>
                        {editing.reviewId === review._id ? (
                          <>
                            <Button onClick={handleUpdate} variant="contained" color="primary">Update</Button>
                            <Button onClick={() => setEditing({ reviewId: null, replyId: null, isReview: false })}>Cancel</Button>
                          </>
                        ) : (
                          <>
                            <IconButton onClick={() => handleReplyClick(review._id)}><MdOutlineReply /></IconButton>
                            <IconButton onClick={() => handleEdit(review._id, null, review.comment?.text || '', true)}><MdEdit /></IconButton>
                            <IconButton onClick={() => handleDelete(review._id, null, true)}><MdDelete /></IconButton>
                          </>
                        )}
                      </div>
                    </div>
                    {showReplies[review._id] && review.reply && review.reply.map(reply => (
                      <div key={reply._id} className="reply-box">
                        <p>{reply.comment?.text || 'No text available'}</p>
                        <div className='reply-buttons'>
                          <IconButton onClick={() => handleEdit(review._id, reply._id, reply.comment?.text || '', false)}><MdEdit /></IconButton>
                          <IconButton onClick={() => handleDelete(review._id, reply._id, false)}><MdDelete /></IconButton>
                        </div>
                      </div>
                    ))}
                    <Button onClick={() => handleShowReplies(review._id)}>
                      {showReplies[review._id] ? 'Hide Replies' : 'Show Replies'}
                    </Button>
                    {currentReviewId === review._id && (
                      <div className='reply-form'>
                        <TextField
                          fullWidth
                          value={replyText}
                          onChange={handleReplyChange}
                          placeholder="Write a reply..."
                          multiline
                        />
                        <Button onClick={handlePostReply} variant="contained" color="primary">Post Reply</Button>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatingAndReviews;
