
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './app.css'
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
const Sendnotifcations = () => {
    const [recipient, setRecipient] = useState('');
    const [message, setMessage] = useState('');
    const handleSendNotification = () => {
        console.log('Selected Recipient:', recipient);
        console.log('Message:', message);
        setRecipient('')
        setMessage('')
      };
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const navigator = useNavigate()
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>Send Notification </h1>
    <div className="dashboard">
     <div className='styles-div'>
      <div className="chart-container">      
      
      <div >
      <div className="form-group">
        <label className="h5-heaidng-invouice">Send To:</label>
        <select
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
          className="select"
        >
          <option value="" disabled>Select Recipients</option>
          <option value="both">Both Buyers and Sellers</option>
          <option value="buyers">Buyers</option>
          <option value="sellers">Sellers</option>
        </select>
      </div>
      <div className="form-group">
        <label className="h5-heaidng-invouice">Enter Message:</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="10"
          className="textarea"
        ></textarea>
      </div>
      
      <div className='btn-job-histoiuyr' style={{width:'100%'}}><ButtonDahbaord handleclick={handleSendNotification} textButton="Send Notification" height={35} width={'30%'} background='purple' /></div>
    </div>
      
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Sendnotifcations