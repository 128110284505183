import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, TextField, Button, CircularProgress } from '@mui/material';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import { IoCloseOutline } from "react-icons/io5";

const PopupCustomer = ({ textpopupBtn, HandleLogout, show, onHide, showBtn, popupData, onViewInvoice }) => {
  return (
    <Dialog
      open={show}
      onClose={onHide}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        {popupData.name}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onHide}
          aria-label="close"
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <IoCloseOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className='popup-pending-appliction'>
          <div className='div-left-popup' style={{ width: '50%' }}>
            <Typography variant="h6">Car Details</Typography>
            {[
              { label: 'Buyer Name', value: popupData.buyerName || 'Buyer Name:' },
              { label: 'Car', value: popupData.car || '' },
              { label: 'Car Type', value: popupData.carType || '' },
              { label: 'Car Plate Number', value: popupData.carPlate || '' }
            ].map((item, index) => (
              <div className='popup-inputfeild-application'>
                <p className='vat-number-api'>{item.label}:</p>
                <input className='valt-number-input-feild' placeholder='text'  value={item.value} readOnly />
              </div>
            ))}
          </div>
          <div className='div-left-popup' style={{ width: '50%' }}>
            <Typography variant="h6">Order Details</Typography>
            {[
              { label: 'Order ID', value: popupData.orderId || '' },
              { label: 'Order Date', value: popupData.orderDate || '' },
              { label: 'Order Time', value: popupData.orderTime || '' },
              { label: 'Status', value: popupData.status || '' }
            ].map((item, index) => (
              <div className='popup-inputfeild-application'>
                <p className='vat-number-api'>{item.label}:</p>
                <input className='valt-number-input-feild' placeholder='text'  value={item.value} readOnly />
              </div>
              // <div key={index} className='popup-inputfeild-application'>
              //   <Typography variant="body2">{item.label}:</Typography>
              //   <TextField
              //     fullWidth
              //     variant="outlined"
              //     margin="normal"
              //     value={item.value}
              //     InputProps={{ readOnly: true }}
              //   />
              // </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className='paymanet-div-super-admin'>
          <Typography variant="body2">Payment</Typography>
          <Typography variant="body1">
            {popupData.paymnet} <span style={{ fontSize: 12 }}>AED</span>
          </Typography>
        </div>
        <div className='second-payment-div' >
        <ButtonDahbaord
            handleclick={onViewInvoice}
            textButton={'View Invoice'}
            height={32}
            width={"24%"}
            background='transparent'
            />
            <ButtonDahbaord
            handleclick={onViewInvoice}
            textButton={'Delete'}
            height={32}
            width={"18%"}
            background='transparent'
            />
            <ButtonDahbaord
            handleclick={onViewInvoice}
            textButton={"Share details"}
            height={32}
            width={"24%"}
            background='purple'
            />
          
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default PopupCustomer;
