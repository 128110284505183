import React, { useEffect, useRef, useState } from 'react';
import './ChatApp.css';
import { LuSend } from "react-icons/lu";
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import Logo from '../../assets/images/image 1.png'
import AppLogo from '../../assets/images/app.logo.jpeg'
import { useNavigate } from 'react-router-dom';
const conversations = [
  {
    id: 127212,
    title: "Conversation 1",
    messages: [
      {
        text: "Hi, I'm having trouble finding the drop-off spot for my car wash appointment. Can you help?",
        type: "received",
        time: "16 May 2024 at 10:00 AM",
      },
      {
        text: "Sure thing! Could you provide your current location?",
        type: "sent",
        time: "16 May 2024 at 10:00 AM",
      },
      {
        text: "I'm at Main Street and Elm Avenue.",
        type: "received",
        time: "16 May 2024 at 10:00 AM",
      },
      {
        text: "Got it. The nearest drop-off spot is behind the gas station on Main Street. Look for our signage there.",
        type: "sent",
        time: "16 May 2024 at 10:00 AM",
      },
      {
        text: "Thanks!",
        type: "received",
        time: "16 May 2024 at 10:00 AM",
      },
    ],
  },
  {
    id: 127213,
    title: "Conversation 2",
    messages: [
      {
        text: "Hello, I need to reschedule my appointment.",
        type: "received",
        time: "17 May 2024 at 11:00 AM",
      },
      {
        text: "No problem! What day works best for you?",
        type: "sent",
        time: "17 May 2024 at 11:05 AM",
      },
      {
        text: "Next Monday, please.",
        type: "received",
        time: "17 May 2024 at 11:06 AM",
      },
    ],
  },
  {
    id: 127214,
    title: "Conversation 3",
    messages: [
      {
        text: "Can you give me an update on my order?",
        type: "received",
        time: "18 May 2024 at 12:00 PM",
      },
      {
        text: "Your order is being processed and will be delivered by tomorrow.",
        type: "sent",
        time: "18 May 2024 at 12:05 PM",
      },
    ],
  },
];

const interactionHistory = [
  {
    status: 'Resolved',
    description: "Customer Didn't show up",
    service: 'Tire Wash',
    agent: 'Franc Lee',
    date: '13/02/24',
    time: '15:35',
  },
  // Add more history items here
];

const ChatApp = () => {
    const [selectedConversation, setSelectedConversation] = useState(conversations[0]);
    const [messageText, setMessageText] = useState('');
    const messagesEndRef = useRef(null);
  
    const handleConversationClick = (conversation) => {
      setSelectedConversation(conversation);
    };
  
    const handleSendMessage = () => {
      if (messageText.trim() === '') return;
  
      const newMessage = {
        text: messageText,
        type: 'sent',
        time: new Date().toLocaleString(),
      };
  
      const updatedConversation = {
        ...selectedConversation,
        messages: [...selectedConversation.messages, newMessage],
      };
  
      setSelectedConversation(updatedConversation);
      setMessageText('');
    };
  
    useEffect(() => {
      // Scroll to the bottom of the messages container when selectedConversation changes or a new message is sent
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedConversation.messages]);
  const navigation = useNavigate()
    return (
      <div className="chat-app">
        
        <div className="active-conversations" style={{marginTop:0}}>
        <div className="logo" style={{width:'90%',cursor:'pointer'}} onClick={()=>navigation('/super-admin-dashboard')}>
          <img src={Logo} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
        </div>
          <h2 className='main-heading2 addtion-styling-text'>Active Conversations</h2>
          {conversations.map((conv) => (
            
            <div
              key={conv.id}
              className={`conversation ${
                selectedConversation.id === conv.id ? 'active' : ''
              }`}
              onClick={() => handleConversationClick(conv)}
            >
              <div className='second-row-customer'>
                <img className='image-pending-css' src={AppLogo}/>
                <div className='top-custorm-width'>
                <h5 className='h5-class-top-class'>{conv.title}</h5>
                <p className='paragprh-customer-com' style={{width:'94%'}}>{conv.messages[conv.messages.length - 1].text}</p>
                </div>
                
              </div>
              {/* <div>{conv.messages[conv.messages.length - 1].text}</div> */}
            </div>
          ))}
        </div>
        
        <div className="current-chat">
            <div className='row-invoice'>
            <h2  className='main-heading2'>{selectedConversation.title}</h2>
            <ButtonDahbaord  handleclick={''} textButton="End Chats" height={30} width={'15%'} background='purple' />

            </div>
        

          <div className="messages">
            {selectedConversation.messages.map((message, index) => (
              <div key={index} className={`message ${message.type}`}>
                <p>{message.text}</p>
                <span className='time-chat-application'>{message.time}</span>
              </div>
            ))}
            <div ref={messagesEndRef} /> {/* This is the target for scrolling */}
          </div>
          <div className="chat-input">
            <input
              type="text"
              placeholder="Type a message..."
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              style={{
                  outline: 'none',
                  boxShadow: 'none',
                  border: 'none'
                }}
            />
            <LuSend onClick={handleSendMessage} style={{color:'#747EEF',cursor:'pointer'}} />
            {/* <button onClick={handleSendMessage}>Send</button> */}
          </div>
        </div>
  
        <div className="interaction-history">
          <h2 className='main-heading2' style={{paddingBottom:20}}>Interaction History</h2>
          {interactionHistory.map((item, index) => (
            <div key={index} className="history-item">
              <div className="status" >{item.status}</div>
              <div class="heading-h5-later" style={{paddingTop:7}}>{item.description}</div>
              <div className="details">
                <div className='colm-div-width'>
                <p class="paragprh-customer-com">Service:</p>
                <h5 class="h5-class-top-class">{item.service}</h5>
                </div>
                <div className='colm-div-width'>
                <p class="paragprh-customer-com">Agent:</p>
                <h5 class="h5-class-top-class">{item.agent}</h5>
                </div>
              </div>
              <div className="details">
                <div className='colm-div-width'>
                <p class="paragprh-customer-com">Date:</p>
                <h5 class="h5-class-top-class">{item.date}</h5>
                </div>
                <div className='colm-div-width'>
                <p class="paragprh-customer-com">Time:</p>
                <h5 class="h5-class-top-class">{item.time}</h5>
                </div>
              </div>
             
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default ChatApp;