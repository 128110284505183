import TopCampanies from 'admin/components/dashboardcom/TopCampanies';
import CompaniesCom from 'admin/components/jobhistory/companies/CompaniesCom';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import Switch from 'react-switch';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import React, { useEffect, useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import './app.css'
const ServiceFee = () => {
    const [checked, setChecked] = useState(false);
    const handleChange = (nextChecked) => {
        setChecked(nextChecked);
    };
    const reviews = [
        {
          id: 1,
          name: 'Kristin Watson',
          vist: '10:00 AM',
          spent: 200,
          status:'Completed',
          buyerName:'John Doe',
          Service:'Car wash',
          carType:'Sedan',
          carPlate:'A-1234',
          OrderID:"CS-1095",
          OrderDate:'16 May 2024',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          paymnet:'1300',
          img:'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg'
       
        },
        {
          id: 2,
          name: 'Kristin Harry',
          vist: '10:00 AM',
          spent: 1200,
          status:'Not Completed',
          buyerName:'Harry Doe',
          carName:'G Wagon',
          Service:'Car wash',
          carPlate:'A-12114',
          OrderID:"SS-1095",
          OrderDate:'19 May 2024',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          paymnet:'1200',
          img:'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg'

        },
        {
          id: 3,
          name: 'Watson',
          vist: '10:00 AM',
          spent: 200,
          status:'Completed',
          buyerName:'John Doe',
          Service:'Car wash',
          carType:'Sedan',
          carPlate:'A-1234',
          OrderID:"CS-1095",
          OrderDate:'16 May 2024',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          paymnet:'200',
          img:'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg'

        },
        {
          id: 4,
          name: 'Kristin Watson',
          vist: '10:00 AM ',
          spent: 200,
          status:'Not Completed',
          buyerName:'John Doe',
          Service:'Car wash',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          carPlate:'A-1234',
          OrderID:"CS-1095",
          OrderDate:'16 May 2024',
          paymnet:'1000',
          img:'https://blog-cdn.el.olx.com.pk/wp-content/uploads/2022/06/08130128/car-wash.jpg'

        },
      ];
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const navigator = useNavigate()
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    {/* <h1 className='h1-all-heading'> </h1> */}
    <div className='div-rewie-main'>
                    <h1 className='h1-all-heading'>Service Fee</h1>
                    <span className='span-toogle-specific'>Specific Service Provider 
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        offColor="#bcbcbc"
                        onColor="#747EEF"
                        offHandleColor="#ffffff"
                        onHandleColor="#ffffff"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={25}
                        width={48}
                    />
                    </span>
                </div>
    <div className="dashboard">
     
      <div className='styles-div' style={{width:'100%'}}>

      <div className="chart-container">
        <div className='flex'>
        <div className='input-feild-dic-icon'>
        <CiSearch className='icon-serach-box-input' />
            <input placeholder='Search by service provider' className='input-feild-class-job-history'/>
        </div>     

        <div className='btn-job-histoiuyr'><ButtonDahbaord handleclick={()=>navigator('/super-admin-dashboard/companies/all-companies')} textButton="View all" height={35} width={'40%'} background='purple' /></div>
</div>
      </div>
      
      </div>
     
    </div>
    </div>
    </div>
  )
}

export default ServiceFee