
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import SaleCom from 'admin/components/saleCom/SaleCom';
import { PendingCompSkeleton } from 'admin/applications/SkeletonPending';

const AdminSales = () => {
   
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const navigator = useNavigate()
      const reviews = [
        {
          id: 1,
          name: 'Kristin Watson',
          vist: '10:00 AM',
          service:'Car Wash',
          status:'Completed',
          buyerName:'John Doe',
          Service:'Car wash',
          carType:'Sedan',
          carPlate:'A-1234',
          OrderID:"CS-1095",
          OrderDate:'16 May 2024',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          paymnet:'1300',
          img:'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg'
       
        },
        {
          id: 2,
          name: 'Kristin Harry',
          vist: '10:00 AM',
          service:'Car Wash',
          status:'Not Completed',
          buyerName:'Harry Doe',
          carName:'G Wagon',
          Service:'Car wash',
          carPlate:'A-12114',
          OrderID:"SS-1095",
          OrderDate:'19 May 2024',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          paymnet:'1200',
          img:'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg'

        },
        {
          id: 3,
          name: 'Watson',
          vist: '10:00 AM',
          service:'Car Wash',
          status:'Completed',
          buyerName:'John Doe',
          Service:'Car wash',
          carType:'Sedan',
          carPlate:'A-1234',
          OrderID:"CS-1095",
          OrderDate:'16 May 2024',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          paymnet:'200',
          img:'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg'

        },
        {
          id: 4,
          name: 'Kristin Watson',
          vist: '10:00 AM ',
          service:'Car Wash',
          status:'Not Completed',
          buyerName:'John Doe',
          Service:'Car wash',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          carPlate:'A-1234',
          OrderID:"CS-1095",
          OrderDate:'16 May 2024',
          paymnet:'1000',
          img:'https://blog-cdn.el.olx.com.pk/wp-content/uploads/2022/06/08130128/car-wash.jpg'

        },
      ];
      const [loading,setloading] = useState('')
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>Sales </h1>
    <div className="dashboard">
     <div className='styles-div addtionwidth-setting'>
      <div className="chart-container">      
      <div  className='gap-btw-colm'>
        {loading ? (
                  Array.from(new Array(6)).map((_, index) => (
                    <div key={index} style={{ flex: '1 1 calc(25% - 16px)', marginBottom: '16px' }}>
                      <PendingCompSkeleton />
                    </div>
                  ))
                ) : (
                  reviews?.map((item, index) => (
        <div key={index} style={{ flex: '1 1 calc(25% - 16px)' }}>
            <SaleCom service={item?.service} ViewDetails={()=> navigator('/super-admin-dashboard/sales/view-details',{ state: { item: item } })}  ImagePro={item.img} name={item.name}
                  />
        </div>
        ))
        )}
                
        </div>
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default AdminSales