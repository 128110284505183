
import AsyncStorage from '@react-native-async-storage/async-storage';
import PendingComp from 'admin/components/application/PendingComp';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { PendingCompSkeleton } from '../SkeletonPending';
import NoDataImage from '../../../assets/images/nodataImg.png'; // Add the path to your image here

function Pending() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
      const apiUrl = 'https://washta-9006f93279b8.herokuapp.com/api/admin/businessBystatus?status=pending'; // Replace with your API endpoint

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('response.data.data.reverse()', response.data.data.reverse());
        setData(response.data.data.reverse());
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAccept = async (id) => {
    const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
    const apiUrl = `https://washta-9006f93279b8.herokuapp.com/api/admin/businessApprove/${id}`; // Replace with your API endpoint for PATCH request

    try {
      const response = await axios.patch(apiUrl, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData((prevData) => prevData.filter((item) => item._id !== id));
    } catch (err) {
      console.error('Error approving:', err);
    }
  };

  const handleReject = async (id) => {
    const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
    const apiUrl = `https://washta-9006f93279b8.herokuapp.com/api/admin/businessReject/${id}`;

    try {
      const response = await axios.patch(apiUrl, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData((prevData) => prevData.filter((item) => item._id !== id));
    } catch (err) {
      console.error('Error rejecting:', err);
    }
  };

  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AdminSidebar />
      </div>
      <div className='div-Dashbaord'>
        <h1 className='h1-all-heading'>Pending Applications</h1>
        <div className="dashboard">
          <div className='styles-div' style={{ width: '100%',position:'relative'}}>
            <div className='gap-btw-colm'>
              {loading ? (
                Array.from(new Array(6)).map((_, index) => (
                  <div key={index} style={{ flex: '1 1 calc(25% - 16px)', marginBottom: '16px' }}>
                    <PendingCompSkeleton />
                  </div>
                ))
              ) : data?.length === 0 ? (
                <div style={{position:'absolute',top:'50%',left:'25%'}}>
                  <img src={NoDataImage} alt="No Data" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
              ) : (
                data?.map((item, index) => (
                  <div key={index} style={{ flex: '1 1 calc(25% - 16px)' }}>
                    <PendingComp
                      RejectHandle={() => handleReject(item._id)}
                      AcceptHandle={() => handleAccept(item._id)}
                      location={item.business?.location}
                      document={item.business?.uploadDocument}
                      show={true}
                      accept="Accept"
                      reject="Reject"
                      ViewDocument="View Documents"
                      name={item?.username}
                      date={item.business?.cratedAt}
                      time={item.business?.cratedAt}
                      email={item.email}
                      valNumber={item.business?.VATNumber}
                      Ceo={item.business?.position}
                      showBtn={false}
                      TextPopupbtnLast="Approve Request"
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pending;
