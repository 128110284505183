import React from 'react'
import './Supportcomp.css'
const Supportcomp = ({Icon,Icondata}) => {
  return (
    <div className='supportcom-icon'>
        <img src={Icon} className='image-support-div-img'/>
        <h5 className='h5-heaidng-support'>{Icondata}</h5>
        </div>
  )
}

export default Supportcomp