import React, { useEffect, useState } from 'react';

import 'chart.js/auto';
import { AiOutlineClose } from "react-icons/ai";

import bell from '../../assets/images/Bell.png'

import { Popover, Typography } from 'antd';
import AgentSidebar from 'agentfolder/sidebar/agentSideBar';
function AgentDashbaord() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AgentSidebar/>
    </div>
    <div className='div-Dashbaord'>
      <div className='row-div-admin-dashboard'>
    <h1 className='h1-all-heading'>Dashboard </h1>
    <img onClick={handleOpenChange}  src={bell} style={{width:'2%',objectFit:'contain',cursor:'pointer'}} />
    <Popover
      content={
      
      <div className='row-div-admin-dashboard'>
 <a>Notifications</a>
 <AiOutlineClose onClick={hide} />

      </div>
     
    
    }
      // title="Title"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
</Popover>
    </div>
    <div className="dashboard">
     
      <div className='styles-div'>

      
      {/* <Line data={data} /> */}
      {/* <div className="stats" style={{gap:20}}>
        <DashbaordImgtext heading='Total Orders' value='50' img={img1}/>
        <DashbaordImgtext heading='Cancelled Orders' value='42' img={img2}/>
        <DashbaordImgtext heading='Average Sales' value='08' img={img3}/>
      </div> */}
     {/* <TopCustomers/> */}
      </div>
      {/* <div className='styles-div-text' >
       <TopCampanies/>
      </div> */}
    </div>
    </div>
    </div>
  );
}

export default AgentDashbaord;
