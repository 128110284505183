import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

const AdminOtp = ({ email }) => {
  const [otp, setOTP] = useState(['', '', '', '']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // State to hold error message
  const otpBoxes = Array.from({ length: 4 }, (_, index) => index);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);

      // Move focus to the next input box
      if (value.length === 1 && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true)
    const otpValue = otp.join('');
    const payload = {
      code: otpValue,
      role: "admin",
      email: email
    };
    try {
      const response = await axios.post('https://washta-9006f93279b8.herokuapp.com/api/otp/verifiction', payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const { accessToken } = response.data.data;

      // Save user data and access token to localStorage
      localStorage.setItem('superAdmin', JSON.stringify(response.data.data.user));
      localStorage.setItem('accessTokenAdmin', accessToken);

      // Navigate to the dashboard
      
      console.log('OTP verified:', response.data);
      navigate('/super-admin-dashboard');
      // localStorage.setItem('user', JSON.stringify(response.data));
      // localStorage.setItem('accessToken', JSON.stringify(response.data.accessToken));
    } catch (error) {
      console.log(payload)
      console.error('Error during OTP verification:', error);
      setError(error.message); // Set error message in state
    }finally{
      setLoading(false)
    }
    // navigate('/super-admin-dashboard')
  };

  return (
    <div className="otp-container fade-in">
      <h1 className='h1-heaing'>Enter your verification code</h1>
      <p className='paragraph-otp'> Enter 4 digit verification code sent to your registered Email Verification. </p>
      <div className="otp-input-container">
        {otpBoxes.map(index => (
          <input
            key={index}
            ref={el => inputRefs.current[index] = el}
            className="otp-input"
            type="text"
            value={otp[index]}
            maxLength={1}
            onChange={(e) => handleChange(index, e.target.value)}
          />
        ))}
             

      </div>
      {error && <p className='error-paragrhp'>{error}</p>}
      <p className='paragraph-otp addtionopara'>Resend Code</p>
      <Button onClick={handleSubmit} shape="round" className="min-w-[100%] sm:px-1" style={{ fontSize: '16px', background: 'white', color: 'black', marginTop: '20px', fontFamily: "'General Sans', sans-serif" }}>
      {loading ? <Spinner animation="border" style={{color:'#747EEF',width:'1.5em',height:'1.5em'}}  size="sm" /> : ' Submit'} 
      </Button>
      
      
    </div>
  );
};

export default AdminOtp;
