import React, { useEffect } from 'react';
import { RxCross1 } from "react-icons/rx";
import { FiDownload } from "react-icons/fi";
import Imgaaa from '../../../assets/images/profileimagescreen.png';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import './Pending.css';

const Popup = ({ email, textpopupBtn, HandleLogout, show, onHide, popupData, showBtn }) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains('popup-overlay')) {
        onHide();
      }
    };

    if (show) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [show, onHide]);

  if (!show) return null;

  const documentUrl = popupData.document;

  return (
    <div className={`popup-overlay ${show ? 'active' : ''}`} onClick={onHide}>
      <div className={`popup-container ${show ? 'active' : ''}`} onClick={(e) => e.stopPropagation()}>
        <div className="popup-header" style={{ justifyContent: 'space-between', border: 'none' }}>
          <h2>DXB Car Details</h2>
          <RxCross1 onClick={onHide} style={{ cursor: 'pointer' }} />
        </div>
        <div className="popup-body">
          <div className='popup-pending-appliction'>
            <div className='div-left-popup'>
              <h2>Details</h2>
              <div className='popup-inputfeild-application'>
                <p className='vat-number-api'>VAT Number:</p>
                <input className='valt-number-input-feild' placeholder='text' value={popupData.valNumber || ''} readOnly />
              </div>
              <div className='popup-inputfeild-application'>
                <p className='vat-number-api'>Location:</p>
                <input className='valt-number-input-feild' placeholder='text' value={popupData.location || ''} readOnly />
              </div>
              <h2 className='margin-top-class'>Authorized Signatory</h2>
              <div className='second-row-customer customer-pending'>
                <img src={Imgaaa} style={{ width: '10%' }} alt="Profile" />
                <div className='top-custorm-width' style={{ width: '80%' }}>
                  <h5 className='h5-class-top-class'>{popupData.name}</h5>
                  <p className='paragprh-customer-com'>{popupData.email}</p>
                </div>
                <div className='top-custorm-width' style={{ width: '20%', textAlign: 'start' }}>
                  <p className='paragprh-customer-com'>Position</p>
                  <h5 className='h5-class-top-class'>{popupData.Ceo}</h5>
                </div>
              </div>
            </div>
            <div className='div-left-popup extra-div-width'>
              <h2>Verification Document</h2>
              <a href={documentUrl} style={{cursor:'pointer',zIndex:99}} target="_blank" rel="noopener noreferrer" download="verification_document.pdf" >
                <div className='div-verifecation-downlood' style={{overflow:'hidden'}}>
                  <iframe
                    src={documentUrl}
                    title="Verification Document"
                    style={{ width: '100%', border: 'none',height:'100%' }}
                  />
                  <a href={documentUrl} target="_blank" rel="noopener noreferrer" download="verification_document.pdf" className='icon-downlood-verfication'>
                    <FiDownload className='icon-downlood-da' />
                  </a>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="popup-footer" style={{ justifyContent: showBtn ? 'space-between' : 'flex-end', border: 'none' }}>
          {showBtn && (
            <ButtonDahbaord handleclick={onHide} textButton='Reject Request' height={35} width={'40%'} />
          )}
          <ButtonDahbaord handleclick={HandleLogout} textButton={textpopupBtn} height={35} width={'40%'} background='purple' />
        </div>
      </div>
    </div>
  );
};

export default Popup;
