import Sidebar from 'pages/sideBar/SideBar';
import React, { useEffect, useState } from 'react';
import '../app.css';
import SellerShopCom from 'components/sellershopCom/SellerShopCom';
import Switch from 'react-switch';
import { GoPlus } from "react-icons/go";
import PopupSellar from './popupsellar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Box, Skeleton } from '@mui/material';

const SellarShop = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [data, setData] = useState([]);
    const [checked, setChecked] = useState(false);
    const [userid, setUserid] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleChange = (nextChecked) => {
        setChecked(nextChecked);
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const userObject = JSON.parse(storedUser);
            const userId = userObject?.id;
            setUserid(userId);
        } else {
            console.log('No user data found in localStorage.');
        }
    }, []);

    const fetchData = async () => {
        const token = localStorage.getItem('accessToken');
        const apiUrl = 'https://washta-9006f93279b8.herokuapp.com/api/seller/shop';

        try {
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("response", response.data.data);
            setData(response.data.data.reverse());
        } catch (err) {
            console.log(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleAccept = async (id) => {
        const token = localStorage.getItem('accessToken');
        const apiUrl = `https://washta-9006f93279b8.herokuapp.com/api/seller/shop/${id}`;

        try {
            const response = await axios.delete(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success('Successfully deleted');
            console.log('Deleted:', response.data);
            setData(data.filter(item => item._id !== id));
        } catch (err) {
            console.error('Error deleting:', err);
        }
    };

    return (
        <div className='mainDiv-all-sideBar'>
            <div className='div-sidebar'>
                <Sidebar />
            </div>
            <div className='div-Dashbaord'>
                <div className='div-rewie-main'>
                    <h1 className='h1-all-heading'>Shop</h1>
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        offColor="#bcbcbc"
                        onColor="#747EEF"
                        offHandleColor="#ffffff"
                        onHandleColor="#ffffff"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={25}
                        width={48}
                    />
                </div>
                <div className="dashboard">
                    <div className='styles-div-order-sellar'>
                        {isLoading ? (
                            Array.from(new Array(6)).map((_, index) => (
                              <div style={{ background:'#747eef9c', flex: '0.5 calc(30% - 1px)',  padding: '20px', border: '1px solid #e0e0e0', borderRadius: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                               <Skeleton variant="rectangular" width="100%" height={190} sx={{ borderRadius: 3}} />
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 2 }}>
                <Skeleton variant="text" width="60%" height={30} style={{background:'#f1f2fd'}} />
                <Skeleton variant="circular" width={30} height={30} style={{background:'#f1f2fd'}} />
            </Box>
            <Skeleton variant="text" width="100%" height={20} sx={{ mt: 1 }} style={{background:'#f1f2fd'}} />
            <Skeleton variant="text" width="100%" height={20} sx={{ mt: 1 }} style={{background:'#f1f2fd'}} />
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 2 }}>
                <Skeleton variant="rectangular" width="45%" height={30} sx={{ borderRadius: 1 }} style={{background:'#f1f2fd'}} />
                <Skeleton variant="rectangular" width="45%" height={30} sx={{ borderRadius: 1 }} style={{background:'#f1f2fd'}} />
            </Box>
                          </div>
                            ))
                        ) : (
                            data.map((item) => (
                                <div key={item._id} style={{ flex: '0.5 calc(33% - 16px)' }}>
                                    <SellerShopCom
                                        onlcick={() => handleAccept(item._id)}
                                        img={item.coverImage}
                                        Price={item.cost}
                                        timing="08:00 AM - 07:00 PM"
                                        location="Dubai, Sharjah"
                                        Heading={item.shopName}
                                        type={item.type}
                                    />
                                </div>
                            ))
                        )}
                        <div className='seller-shop-item' onClick={togglePopup}>
                            <div className='lightopcity-div'>
                                <GoPlus className='icons-seller' />
                                <p className='add-seller-shop'>Add Shop</p>
                            </div>
                        </div>
                    </div>
                </div>
                {isPopupOpen && (
                    <div>
                        <PopupSellar fetchData={fetchData} id={userid} Cross={togglePopup} />
                    </div>
                )}
            </div>
            <ToastContainer />
        </div>
    );
};

export default SellarShop;
