import AdminSidebar from 'admin/sidebar/AdminSidebar'
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord'
import React from 'react'
import { GoDotFill } from "react-icons/go";
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '../../../assets/images/deleteIcon.png'
const CustomerDetailspg = () => {
    const location = useLocation();
    const { item } = location.state || {};
    const navigation = useNavigate()
console.log(item)
 return (
<div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'78%'}} >
        <h1 className='h1-all-heading' style={{width:'30%'}}>{item.name}</h1>
        <div style={{display:'flex',width:'70%',justifyContent:'end',gap:10}}>
        <ButtonDahbaord background={'purple'} padding={'5px 2px'} handleclick='' textButton='Remove customer' height={35} width={'30%'} />
        </div>
        </div>
   
    <div className="dashboard-customer-details">
    <h2>Details</h2>    
      <div className='div-customer-details-main'> 
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Number Plate:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.numberPlate} readOnly />
            </div>
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Location:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.location} readOnly />
            </div>
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Email:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.email} readOnly />
            </div>
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Phone Number:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.phoneNum} readOnly />
            </div>
          
      </div>
    </div>

    <div className="dashboard-customer-details">
    <h2>Registered Vehicles</h2>    
      <div className='div-customer-details-main'> 
      {item?.car?.map((item)=>(
        <div key={item?.id} className='popup-inputfeild-application addtion-adding' style={{display:'block',flex: '1 1 calc(33.33% - 16px)'}}>
              <p className='vat-number-api' style={{fontWeight:'500'}}>{item?.carName}</p>
              <div className='receipt-footer' style={{justifyContent:'start',gap:5,paddingTop:5}}>
              <p className='vat-number-api' style={{width:'auto'}}>{item?.type}</p>
              <p className='vat-number-api' style={{display:'flex',alignItems:'center'}}><span ><GoDotFill style={{fontSize:10}}/></span>{item?.platenum}</p>
              </div>
              </div>
      ))} 
      </div>
    </div>

    <div className="dashboard-customer-details">
     
    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}} >
    <h2>Job Bookings</h2> 
        <div style={{display:'flex',width:'70%',justifyContent:'end',gap:10}}>
        <ButtonDahbaord background={'purple'} padding={'5px 2px'} handleclick={()=>navigation('/super-admin-dashboard/customers/all-customers/customer-details/view-all-bookings',{ state: { item: item } })} textButton='View all' height={30} width={'16%'} />
        </div>
        </div>  
      <div className='div-customer-details-main' style={{gridTemplateColumns:'repeat(auto-fill, minmax(200px, 1fr))'}}> 
      {item?.Booking?.slice(0, 6).map((item)=>(
        <div key={item?.id} className='booking-div-customer-div' style={{display:'block',flex: '1 1 calc(25% - 16px)'}}>
              <h5 className='h5-class-top-class'>{item?.bookingId}</h5>
              <p className='paragprh-customer-com'>{item?.date}</p>
              <img src={DeleteIcon} className='image-abousulte-delete'/>
              <div className='colm-div-customer-com' style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',paddingTop:15}}>
        <h5 className='paragraph-text-p' style={{fontSize:12}}>Status</h5>
        <h5 className='h5-class-top-class'>{item?.status}</h5>
        </div>
        <div className='colm-div-customer-com' style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%',paddingTop:5}}>
        <h5 className='paragraph-text-p' style={{fontSize:12}}>Total Spent</h5>
        <h5 className='h5-class-top-class'>{item?.totalspent}<span style={{ fontSize: 9 }}> AED</span></h5>
              </div>
              </div>
      ))} 
      </div>
    </div>

    </div>
    </div>
  )
}

export default CustomerDetailspg