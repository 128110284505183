// import React from 'react';
// import Modal from 'react-bootstrap/Modal';
// import { RxCross1 } from "react-icons/rx";
// import Barcode from 'react-barcode';
// import './Recepit.css';
// import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';

// const InvoicePopup = ({ show, onHide, invoiceData }) => {
//   // Concatenate all relevant information into a single string
//   const barcodeData = `    
//     Order ID: ${invoiceData.orderId}
//   `;

//   return (
//     <Modal
//       show={show}
//       onHide={onHide}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header style={{ justifyContent: 'space-between', border: 'none' }}>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Invoice Details
//         </Modal.Title>
//         <RxCross1 onClick={onHide} style={{ cursor: 'pointer' }} />
//       </Modal.Header>
//       <Modal.Body>
//         <div className="receipt-container">
//           <Barcode displayValue={false} value={barcodeData} className="barcode" />
//           <div className="receipt-details">
//             <div className="receipt-row">
//               <span className="receipt-label">Booking Date</span>
//               <span className="receipt-value">{invoiceData.orderDate} | {invoiceData.orderTime}</span>
//             </div>
//             <div className="receipt-row">
//               <span className="receipt-label">Car Plate Number</span>
//               <span className="receipt-value">{invoiceData.carPlate}</span>
//             </div>
//             <div className="receipt-row">
//               <span className="receipt-label">Car Type</span>
//               <span className="receipt-value">{invoiceData.carType}</span>
//             </div>
//             <div className="receipt-row">
//               <span className="receipt-label">Service</span>
//               <span className="receipt-value">Car Wash & Full Cleaning</span>
//             </div>
//             <div className="receipt-row">
//               <span className="receipt-label">Duration</span>
//               <span className="receipt-value">1 hr</span>
//             </div>
//             <div className="receipt-row">
//               <span className="receipt-label">Exterior Cleaning</span>
//               <span className="receipt-value">AED 7.5</span>
//             </div>
//             <div className="receipt-row">
//               <span className="receipt-label">Interior Cleaning</span>
//               <span className="receipt-value">AED 7.5</span>
//             </div>
//             <div className="receipt-row">
//               <span className="receipt-label">Tire Cleaning</span>
//               <span className="receipt-value">AED 5</span>
//             </div>
//             <div className="receipt-row">
//               <span className="receipt-label">Tax & Fees</span>
//               <span className="receipt-value">AED 0.00</span>
//             </div>
//             <div className="receipt-row">
//               <span className="receipt-label">Total</span>
//               <span className="receipt-value">AED 20.00</span>
//             </div>
//             <div className="receipt-row">
//               <span className="receipt-label">Payment</span>
//               <span className="receipt-value">**** **** **** 1578</span>
//             </div>
//             <div className="receipt-row">
//               <span className="receipt-label">Order ID</span>
//               <span className="receipt-value">{invoiceData.orderId}</span>
//             </div>
//           </div>
//           <div className="receipt-footer">
//             <div className="total-payment">
//               <span className='paragrph-paynet'>Payment</span>
//               <span>{invoiceData.paymnet} <span style={{fontSize: '9px'}}>AED</span></span>
//             </div>
//             <ButtonDahbaord background={'purple'} padding={'5px 2px'}  textButton='Download E-Receipt' height={35} width={'25%'} />

//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// }

// export default InvoicePopup;
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import { IoCloseOutline } from "react-icons/io5";
import Barcode from 'react-barcode';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import './Recepit.css';

const InvoicePopup = ({ show, onClose, invoiceData }) => {
  if (!show) return null;

  // Concatenate all relevant information into a single string
  const barcodeData = `Order ID: ${invoiceData.orderId}`;

  return (
    <Dialog
      open={show}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          padding: 2,
          borderRadius: 2,
        }
      }}
    >
      <DialogTitle>
        Invoice Details
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <IoCloseOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="invoice-popup-content">
        <Barcode  displayValue={false} value={barcodeData} className="barcode" />          <div className="receipt-details">
            {[
              { label: 'Booking Date', value: `${invoiceData.orderDate} | ${invoiceData.orderTime}` },
              { label: 'Car Plate Number', value: invoiceData.carPlate },
              { label: 'Car Type', value: invoiceData.carType },
              { label: 'Service', value: 'Car Wash & Full Cleaning' },
              { label: 'Duration', value: '1 hr' },
              { label: 'Exterior Cleaning', value: 'AED 7.5' },
              { label: 'Interior Cleaning', value: 'AED 7.5' },
              { label: 'Tire Cleaning', value: 'AED 5' },
              { label: 'Tax & Fees', value: 'AED 0.00' },
              { label: 'Total', value: 'AED 20.00' },
              { label: 'Payment', value: '**** **** **** 1578' },
              { label: 'Order ID', value: invoiceData.orderId }
            ].map((item, index) => (
              <div key={index} className="receipt-row">
                <span variant="body2" className="receipt-label">{item.label}</span>
                <Typography variant="body2" className="receipt-value">{item.value}</Typography>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      {/* <DialogActions>
        <div className="popup-footer">
          <div className="total-payment">
            <Typography variant="body2" className="paragraph-payment">Payment</Typography>
            <Typography variant="body1">
              {invoiceData.payment} <span style={{ fontSize: '9px' }}>AED</span>
            </Typography>
          </div>
          <ButtonDahbaord
            background={'purple'}
            padding={'5px 2px'}
            textButton='Download E-Receipt'
            height={35}
            width={'25%'}
          />
        </div>
      </DialogActions> */}
      <DialogActions>
        <div className='paymanet-div-super-admin'>
          <Typography variant="body2">Payment</Typography>
          <Typography variant="body1">
            {invoiceData.payment} <span style={{ fontSize: 12 }}>AED</span>
          </Typography>
        </div>
        <div className='second-payment-div' >
        
            <ButtonDahbaord
            // handleclick={onViewInvoice}
            textButton={"Download E-Receipt"}
            height={32}
            width={"50%"}
            background='purple'
            />
          
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default InvoicePopup;
