// // import React from 'react'
// // import Modal from 'react-bootstrap/Modal';
// // import { RxCross1 } from "react-icons/rx";
// // import LogutIcon from '../../assets/images/logutpopup.png'
// // import './LogoutPopup.css'
// // import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
// // const LogoutPopup = (props) => {
// //   return (
// //     <Modal
// //       {...props}
// //       size="lg"
// //       aria-labelledby="contained-modal-title-vcenter"
// //       centered
// //     >
        
// //       <Modal.Header style={{justifyContent:'space-between',border:'none'}} >
     

// //         <Modal.Title id="contained-modal-title-vcenter">
// //         Logout
// //         </Modal.Title>
// //         <RxCross1 onClick={props.onHide} style={{cursor:'pointer'}} />
// //       </Modal.Header>
// //       <Modal.Body>
// //         <img src={LogutIcon} className='image-logut-div'/>
// //         <p className='paragrph-logut'>
// //         Are you sure you want to log out?
// //         </p>
// //       </Modal.Body>
// //       <Modal.Footer style={{justifyContent:'space-between',border:'none'}}>
// //       <ButtonDahbaord handleclick={props.onHide} textButton='Cancel' height={35} width={85} />
// //             <ButtonDahbaord handleclick={props.HandleLogout} textButton='Logout' height={35} width={85} background='purple' />
// //       </Modal.Footer>
// //     </Modal>
// //   )
// // }

// // export default LogoutPopup
// import React from 'react';
// import './LogoutPopup.css';
// import { RxCross1 } from "react-icons/rx";
// import LogutIcon from '../../assets/images/logutpopup.png';

// const LogoutPopup = ({ isOpen, onClose, onLogout }) => {
//   if (!isOpen) return null; // If the modal is not open, return null

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">
//         <div className="modal-header">
//           <h2 className="modal-title">Logout</h2>
//           <RxCross1 onClick={onClose} className="close-icon" />
//         </div>
//         <div className="modal-body">
//           <img src={LogutIcon} alt="Logout Icon" className="image-logout" />
//           <p className="paragraph-logout">
//             Are you sure you want to log out?
//           </p>
//         </div>
//         <div className="modal-footer">
//           <button className="button button-cancel" onClick={onClose}>Cancel</button>
//           <button className="button button-logout" onClick={onLogout}>Logout</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LogoutPopup;
import React, { useEffect } from 'react';
import { RxCross1 } from "react-icons/rx";
import LogutIcon from '../../assets/images/logutpopup.png'
import './LogoutPopup.css'
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
function LogoutPopup({ isOpen, onClose, data ,onLogout}) {
    useEffect(() => {
        // Handle clicks outside of the modal content
        const handleClickOutside = (event) => {
          if (event.target.classList.contains('modal-overlay')) {
            onClose();
          }
        };
    
        if (isOpen) {
          document.addEventListener('click', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [isOpen, onClose]);
    
      return (
        <div className={`modal-overlay ${isOpen ? 'open' : 'close'}`}>
          <div id='classname-id-modal' className={`modal-content ${isOpen ? 'slide-in' : 'slide-out'}`}>
          <div className='row-modal-popup margin-bottom' >
          <h2 className='h2-heading-main-mai'>Logout</h2>
            <button onClick={onClose} className="close-button"><RxCross1/></button>
          </div>
          <div className="modal-body" style={{display:'flex',justifyContent:'center'}}>
           <img src={LogutIcon} alt="Logout Icon" className="image-logout" />
          
         </div>
         <h2 className='are-logout-btn'>Are you sure you want to log out?</h2>
            <div className="modal-footer-logut">
            <ButtonDahbaord handleclick={onClose} textButton='Cancel' height={35} width={85} />
           <ButtonDahbaord  handleclick={onLogout} textButton='Logout' height={35} width={85} background='purple' />
         </div>
            {/* Add more details as needed */}
          </div>
        </div>
      );
    }

export default LogoutPopup;
