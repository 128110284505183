import React, { useState } from 'react';
import './styles.css';
import { ImCross } from "react-icons/im";
import { Text } from '../../components/Text';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

const APiURl = 'http://43.204.144.164/api/subscription';

const App = ({ Cross }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    CompanyName: '',
    AddionText: '',
    selectedOption: false,
    Payment: false
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value === 'true' });
  };

  const handleSubmit = () => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.CompanyName
    ) {
      toast.error("Please fill in all required details");
    } else {
      setLoading(true);
      axios.post(APiURl, {
        name: formData.name,
        email: formData.email,
        phone: formData.phoneNumber,
        companyName: formData.CompanyName,
        registerOnwashta: formData.selectedOption,
        acceptOnlinePayment: formData.Payment,
        additionalText: formData.AddionText
      })
        .then(response => {
          console.log(response.data);
          toast.success("Subscription successful");
          setFormData({
            name: '',
            email: '',
            phoneNumber: '',
            CompanyName: '',
            AddionText: '',
            selectedOption: false,
            Payment: false
          });
          setStep(1);
        })
        .catch(error => {
          console.error('There was a problem with the Axios operation:', error);
          if (error.response) {
            console.error('Server responded with:', error.response.data);
          }
          toast.error("Subscription failed");
        })
        .finally(() => {
          setTimeout(()=>{
            setLoading(false);
            Cross();
          },[1000])
          
        });
    }
  };

  const nextStep = () => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.phoneNumber 
    ) {
      toast.error("Please fill in all required details");
    } else {
      setStep(step + 1);
    }
  };

  const continerNextStep = () => {
    if (!formData.CompanyName) {
      toast.error("Please fill in all required details");
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <div className="app">
      {step === 1 && (
        <div className="popup-background">
          <div className="popup" style={{height:'23em'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2>Subscribe to Washta</h2>
              <ImCross onClick={Cross} style={{ color: '#8891F1', cursor: 'pointer' }} />
            </div>
            <Text style={{ color: 'black' }} size="sm" as="p" className="w-[88%] text-black-900_bf md:w-full color:'black">Name <sup>*</sup></Text>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
            <Text style={{ color: 'black' }} size="sm" as="p" className="w-[88%] text-black-900_bf md:w-full color:'black">Email <sup>*</sup></Text>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            <Text style={{ color: 'black' }} size="sm" as="p" className="w-[88%] text-black-900_bf md:w-full color:'black">Phone Number <sup>*</sup></Text>
            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            <div className='row-poup'>
              <button className='btn-left' onClick={Cross}>Cancel</button>
              <button className='btn-right' onClick={nextStep}>Continue</button>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="popup-background">
          <div className="popup" style={{height:'21em'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2>Subscribe to Washta</h2>
              <ImCross onClick={Cross} style={{ color: '#8891F1', cursor: 'pointer' }} />
            </div>
            <Text style={{ color: 'black' }} size="sm" as="p" className="w-[88%] text-black-900_bf md:w-full color:'black">Company Name <sup>*</sup></Text>
            <input
              type="text"
              name="CompanyName"
              placeholder="Company Name"
              value={formData.CompanyName}
              onChange={handleChange}
            />
            <Text style={{ color: 'black', marginTop: 20 }} size="sm" as="p" className="w-[88%] text-black-900_bf md:w-full color:'black">Are you a registered carwash company?</Text>
            <FormGroup>
              <FormControlLabel
                name='selectedOption'
                value={true}
                control={<Checkbox style={{ color: 'black', borderRadius: '50%' }} />}
                label="Yes"
                checked={formData.selectedOption === true}
                onChange={handleCheckboxChange}
              />
              <FormControlLabel
                name='selectedOption'
                value={false}
                control={<Checkbox style={{ color: 'black', borderRadius: '50%' }} />}
                label="No"
                checked={formData.selectedOption === false}
                onChange={handleCheckboxChange}
              />
            </FormGroup>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button className='btn-left' onClick={prevStep}>Back</button>
              <button className='btn-right' onClick={continerNextStep}>Continue</button>
            </div>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="popup-background">
          <div className="popup" style={{height:'21em'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2>Subscribe to Washta</h2>
              <ImCross onClick={Cross} style={{ color: '#8891F1', cursor: 'pointer' }} />
            </div>
            <Text style={{ color: 'black' }} size="sm" as="p" className="w-[88%] text-black-900_bf md:w-full color:'black">Do you accept online payment?</Text>
            <FormGroup>
              <FormControlLabel
                name='Payment'
                value={true}
                control={<Checkbox style={{ color: 'black', borderRadius: '50%' }} />}
                label="Yes"
                checked={formData.Payment === true}
                onChange={() => setFormData({ ...formData, Payment: true })}
              />
              <FormControlLabel
                name='Payment'
                value={false}
                control={<Checkbox style={{ color: 'black', borderRadius: '50%' }} />}
                label="No"
                checked={formData.Payment === false}
                onChange={() => setFormData({ ...formData, Payment: false })}
              />
            </FormGroup>
            <Text style={{ color: 'black' }} size="sm" as="p" className="w-[88%] text-black-900_bf md:w-full color:'black">Additional details or notes</Text>
            <input
              type="text"
              name="AddionText"
              placeholder="Additional details or notes"
              value={formData.AddionText}
              onChange={handleChange}
            />
            
             {/* {loading && <Spinner type="danger" />} */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button className='btn-left' onClick={prevStep}>Back</button>
              <button className='btn-right' onClick={handleSubmit} disabled={loading}>
                {loading ? <Spinner size="sm" animation="border" variant="light" /> : 'Submit'}
              </button>
            </div>
          </div>
        
        </div>
      )}
        <ToastContainer position="top-center" />
    </div>
  );
};

export default App;
