import React, { useState } from 'react'
import { BsThreeDotsVertical } from "react-icons/bs";
import './app.css'
import { useNavigate } from 'react-router-dom';
import { Popover, Typography } from '@mui/material';
const SaleCom = ({name,ImagePro,ViewDetails,service}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  return (
    <div className='main-pending-colm'>
      <div className='second-row-customer'>
        <img src={ImagePro} style={{width:'3em',objectFit:'cover',height:'3em',borderRadius:5}}/>
        <div className='top-custorm-width' style={{position:'relative'}}>
          <h5 className='h5-heaidng-invouice'>{name}</h5>
           <BsThreeDotsVertical onClick={handleClick} style={{cursor:'pointer'}} className='threedor-icon' />
        </div>
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
<p className='paragraph-text-p' style={{textDecoration:'underline',padding:'0px 10px',cursor:'pointer'}} onClick={ViewDetails} >View details</p> 
<p className='paragraph-text-p' style={{textDecoration:'underline',marginTop:5,padding:'0px 10px',color:'red',cursor:'pointer'}} >Delete</p> 
      </Popover>
      </div>
      <div className='div-coml-main-sales'>
      <div className='second-row-customer'>
      <p className='paragprh-customer-com'>Service</p>
      <p className='heading-sub-customer-com'>{service}</p>
      </div>
      <div className='second-row-customer'>
      <p className='paragprh-customer-com'>Location</p>
      <p className='heading-sub-customer-com' style={{textAlign:'end',width:'60%',whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>B1, ABC Mall Parking, Dubai, UAE</p>
      </div>
      </div>
      </div>
  )
}

export default SaleCom