import React, { useEffect } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import NotFound from "pages/NotFound";
import Home1 from "pages/Home1";
import PrivPolicy from "pages/Privacy";
import BecomeASeller from "pages/becomeASeller/BecomeASeller";
import AddBusniessDetails from "pages/becomeASeller/AddBusniessDetails";
import Dashboard from "pages/clientDashbaord/dashboard/Dashboard";
import Incoice from "pages/clientDashbaord/dashboard/invoice/Incoice";
import OrderDashbaord from "pages/clientDashbaord/dashboard/order/OrderDashbaord";
import SellarShop from "pages/clientDashbaord/dashboard/sellarShop/SellarShop";
import RatingAndReviews from "pages/clientDashbaord/dashboard/ratingandReviews/RatingAndReviews";
import Notification from "pages/clientDashbaord/dashboard/notification/Notification";
import SupportSeller from "pages/clientDashbaord/dashboard/support/SupportSeller";
import Login from "pages/login/Login";
import { isAuthenticated } from "pages/utils/Utils";
import LoginAdmin from "admin/loginadmin/Loginadmin";
import AdminDashbaord from "admin/adminDashboard/AdminDashboard";
import Pending from "admin/applications/pending/Pending";
import Rejected from "admin/applications/rejected/Rejected";
import Approved from "admin/applications/approved/Approved";
import Customers from "admin/jobHistory/customers/Customers";
import AllCustomers from "admin/jobHistory/customers/AllCustomers";
import CustomerDetailspg from "admin/components/jobhistory/CustomerDetailspg";
import ViewAllBooking from "admin/jobHistory/customers/ViewAllBooking";
import Campanies from "admin/jobHistory/campanies/Campanies";
import ViewAllCompanies from "admin/components/jobhistory/companies/ViewAllCompanies";
import DetailsCompaniesCom from "admin/components/jobhistory/companies/DetailsCom";
import NotVerifiedUser from "pages/login/NotVerifiedUser";
import ServiceTiming from "admin/servicetiming/ServiceTiming";
import CustomerServiceBuyer from "admin/customerServiceBuyer/CustomerServiceBuyer";
import CustomerSeller from "admin/customerServiceBuyer/CustomerSeller";
import Sendnotifcations from "admin/sendnotification/SendNotification";
import AdminAgent from "admin/agent/Agent";
import AdminSales from "admin/sales/Sales";
import AllCustomersAppRe from "admin/allUsers/allCustomersAppRe/AllCustomersAppRe";
import AllCompaniesAppRe from "admin/allUsers/allCompaniesAppRe/AllCompaniesAppRe";
import DetailsSalePg from "admin/sales/DetailsSalePg";
import AgentLogin from "agentfolder/agentLogin/AgentLogin";
import AgentDashbaord from "agentfolder/agentDashboard/AgentDashboard";
import AgentChatSupport from "agentfolder/agentchatsupport/AgentChatSupport";
import AgentSale from "agentfolder/agentSale/AgentSale";
import AgentServiceFee from "agentfolder/agentservicefee/AgentServiceFee";
import AgentPromoCode from "agentfolder/agentpromocode/AgentPromoCode";
import AgentEditProfile from "agentfolder/agentEditProfile/AgentEditProfile";
import AllRatingReviews from "pages/clientDashbaord/dashboard/ratingandReviews/AllRatingReviews";
import ServiceFee from "admin/serviceFee/ServiceFee";
import PromoCode from "admin/PromoCode/PromoCode";
import ChatApp from "admin/adminchats/Chats";

const ProjectRoutes = () => {
  const authStatus = isAuthenticated();

  const routes = useRoutes([
    { path: "/", element: authStatus === "user" ? <Navigate to="/become-a-seller/dashboard" /> : authStatus === "admin" ? <Navigate to="/super-admin-dashboard" /> : <Home1 /> },
    { path: "/PrivacyPolicy", element: <PrivPolicy /> },
    { path: "/super-admin-dashboard", element: authStatus === "admin" ? <AdminDashbaord /> : <Navigate to="/" /> },
    { path: "/become-a-seller", element: authStatus === "user" ? <Navigate to="/become-a-seller/dashboard" /> : <BecomeASeller /> },
    { path: "/super-admin-login", element: <LoginAdmin /> },
    { path: "/agent-login", element: <AgentLogin /> },
    { path: "/agent-dashboard", element: <AgentDashbaord /> },
    { path: "/agent/agent-chat-support", element: <AgentChatSupport /> },
    { path: "/agent/agent-service-fee", element: <AgentServiceFee /> },
    { path: "/agent/agent-promo-code", element: <AgentPromoCode /> },
    { path: "/agent/agent-edit-profile", element: <AgentEditProfile /> },

    { path: "/agent/agent-sale", element: <AgentSale /> },

    { path: "/not-verified", element: <NotVerifiedUser /> },
    { path: "/sign-in", element: authStatus === "user" ? <Navigate to="/become-a-seller/dashboard" /> : <Login /> },
    { path: "*", element: <NotFound /> },

    // Protected Routes for Users
    { path: "/become-a-seller/business-details", element:<AddBusniessDetails /> },
    { path: "/become-a-seller/dashboard", element: <ProtectedRoute element={<Dashboard />} authStatus="user" /> },
    { path: "/become-a-seller/notification", element: <ProtectedRoute element={<Notification />} authStatus="user" /> },
    { path: "/become-a-seller/support", element: <ProtectedRoute element={<SupportSeller />} authStatus="user" /> },
    { path: "/become-a-seller/invoices", element: <ProtectedRoute element={<Incoice />} authStatus="user" /> },
    { path: "/become-a-seller/orders", element: <ProtectedRoute element={<OrderDashbaord />} authStatus="user" /> },
    { path: "/become-a-seller/shops", element: <ProtectedRoute element={<SellarShop />} authStatus="user" /> },
    { path: "/become-a-seller/rating-and-reviews", element: <ProtectedRoute element={<RatingAndReviews />} authStatus="user" /> },
    { path: "/become-a-seller/rating-and-reviews/all-rating-reviews", element: <ProtectedRoute element={<AllRatingReviews />} authStatus="user" /> },

    // Protected Routes for Admins
    { path: "/super-admin-dashboard/promo-code", element: <ProtectedRoute element={<PromoCode />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/chats-screen", element: <ProtectedRoute element={<ChatApp />} authStatus="admin" /> },

    { path: "/super-admin-dashboard/service-fee", element: <ProtectedRoute element={<ServiceFee />} authStatus="admin" /> },
    { path: "/super-admin-dashboard", element: <ProtectedRoute element={<AdminDashbaord />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/pending", element: <ProtectedRoute element={<Pending />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/rejected", element: <ProtectedRoute element={<Rejected />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/customers", element: <ProtectedRoute element={<Customers />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/customers/all-customers", element: <ProtectedRoute element={<AllCustomers />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/all-users/all-customers", element: <ProtectedRoute element={<AllCustomersAppRe />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/all-users/all-companies", element: <ProtectedRoute element={<AllCompaniesAppRe />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/sales/view-details", element: <ProtectedRoute element={<DetailsSalePg />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/customers/all-customers/customer-details", element: <ProtectedRoute element={<CustomerDetailspg />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/customers/all-customers/customer-details/view-all-bookings", element: <ProtectedRoute element={<ViewAllBooking />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/approved", element: <ProtectedRoute element={<Approved />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/companies", element: <ProtectedRoute element={<Campanies />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/companies/all-companies", element: <ProtectedRoute element={<ViewAllCompanies />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/companies/all-companies/companies-details", element: <ProtectedRoute element={<DetailsCompaniesCom />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/service-timing", element: <ProtectedRoute element={<ServiceTiming />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/customer-service-buyer", element: <ProtectedRoute element={<CustomerServiceBuyer />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/customer-service-seller", element: <ProtectedRoute element={<CustomerSeller />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/send-notifications", element: <ProtectedRoute element={<Sendnotifcations />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/add-agents", element: <ProtectedRoute element={<AdminAgent />} authStatus="admin" /> },
    { path: "/super-admin-dashboard/sales", element: <ProtectedRoute element={<AdminSales />} authStatus="admin" /> },
  ]);

  return routes;
};

const ProtectedRoute = ({ element, authStatus }) => {
  const currentAuthStatus = isAuthenticated();

  if (currentAuthStatus && currentAuthStatus === authStatus) {
    return element;
  }

  if (authStatus === "user") {
    return <Navigate to="/sign-in" />;
  }

  if (authStatus === "admin") {
    return <Navigate to="/super-admin-login" />;
  }

  return <Navigate to="/" />;
};

export default ProjectRoutes;
