import React, { useEffect } from 'react';
import './Modal.css';
import { RxCross1 } from "react-icons/rx";

function PopupInvoice({ isOpen, onClose, data }) {
    useEffect(() => {
        // Handle clicks outside of the modal content
        const handleClickOutside = (event) => {
          if (event.target.classList.contains('modal-overlay')) {
            onClose();
          }
        };
    
        if (isOpen) {
          document.addEventListener('click', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [isOpen, onClose]);
    
      return (
        <div className={`modal-overlay ${isOpen ? 'open' : 'close'}`}>
          <div className={`modal-content ${isOpen ? 'slide-in' : 'slide-out'}`}>
          <div className='row-modal-popup margin-bottom' >
          <h2 className='h2-heading-main'>Invoice Details</h2>
            <button onClick={onClose} className="close-button"><RxCross1/></button>
          </div>
         
           
            <div className='popup-pending-appliction'>
           
            <div className='popup-inputfeild-application addtionwidth' >
              <p className='vat-number-api'>BillingStatus:</p>
              <input className='valt-number-input-feild' placeholder='text' value={data?.billingStatus || ''} readOnly />
            </div>
            <div className='popup-inputfeild-application addtionwidth'>
              <p className='vat-number-api'>Cost:</p>
              <input className='valt-number-input-feild' placeholder='text' value={ data?.cost + " AED"  || ''} readOnly />
            </div>
            </div>
            <div className='popup-pending-appliction'>
           
            <div className='popup-inputfeild-application addtionwidth' >
              <p className='vat-number-api' style={{width:"25%"}}>CustomerId:</p>
              <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={data?.customerId || ''} readOnly />
            </div>
            <div className='popup-inputfeild-application addtionwidth'>
              <p className='vat-number-api' style={{width:"25%"}}>Location:</p>
              <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={ data?.location?.text || ''} readOnly />
            </div>
            </div>
            <div className='popup-pending-appliction'>
           
            <div className='popup-inputfeild-application addtionwidth' >
              <p className='vat-number-api' style={{width:"25%"}}>Date:</p>
              <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={data?.date || ''} readOnly />
            </div>
            <div className='popup-inputfeild-application addtionwidth'>
              <p className='vat-number-api' style={{width:"25%"}}>Status:</p>
              <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={ data?.status || ''} readOnly />
            </div>
            </div>
            {/* Add more details as needed */}
          </div>
        </div>
      );
    }

export default PopupInvoice;
