import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { RxCross1 } from "react-icons/rx";
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';

const PopupCompanies = ({ textpopupBtn, HandleLogout, show, onHide, showBtn, popupData, onViewInvoice }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static" // Ensure the modal can be closed by clicking outside
      keyboard // Allow the modal to be closed by pressing the ESC key
    >
      <Modal.Header style={{ justifyContent: 'space-between', border: 'none' }}>
        <Modal.Title id="contained-modal-title-vcenter">
          {popupData.name}
        </Modal.Title>
        <RxCross1 onClick={onHide} style={{ cursor: 'pointer' }} />
      </Modal.Header>
      <Modal.Body>
        <div className='popup-pending-appliction'>
          <div className='div-left-popup' style={{ width: '50%' }}>
            <h2>Company Details</h2>
            <div className='popup-inputfeild-application'>
              <p className='vat-number-api'>Company:</p>
              <input className='valt-number-input-feild' placeholder='text' value={popupData.name|| 'Company:'} readOnly />
            </div>
            <div className='popup-inputfeild-application'>
              <p className='vat-number-api'>Service:</p>
              <input className='valt-number-input-feild' placeholder='text' value={popupData.Service || 'Service:'} readOnly />
            </div>
            <div className='popup-inputfeild-application'>
              <p className='vat-number-api'>Location: </p>
              <input className='valt-number-input-feild' placeholder='text' value={popupData.Loc || 'Location: '} readOnly />
            </div>
          </div>
          <div className='div-left-popup' style={{ width: '50%' }}>
            <h2>Order Details</h2>
            <div className='popup-inputfeild-application'>
              <p className='vat-number-api'>Order ID:</p>
              <input className='valt-number-input-feild' placeholder='text' value={popupData.orderId || ''} readOnly />
            </div>
            <div className='popup-inputfeild-application'>
              <p className='vat-number-api'>Order Date:</p>
              <input className='valt-number-input-feild' placeholder='text' value={popupData.orderDate  + "At" + popupData.vist || ''} readOnly />
            </div>
            {/* <div className='popup-inputfeild-application'>
              <p className='vat-number-api'>Order Time:</p>
              <input className='valt-number-input-feild' placeholder='text' value={popupData.orderTime|| ''} readOnly />
            </div> */}
            <div className='popup-inputfeild-application'>
              <p className='vat-number-api'>Status:</p>
              <input className='valt-number-input-feild' placeholder='text' value={popupData.status || ''} readOnly />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: showBtn ? 'space-between' : 'flex-end', border: 'none' }}>
        <div style={{width:'20%'}}>
          <p className='paragrph-paynet'>Payment</p> <p>{popupData.paymnet} <span style={{ fontSize: 9 }}>AED</span></p>
        </div>
        <div style={{display:'flex',width:'60%',justifyContent:'end',gap:10}}>
        <ButtonDahbaord padding={'5px 2px'} handleclick={onViewInvoice} textButton='View Invoice' height={35} width={'30%'} />
        <ButtonDahbaord  padding={'5px 2px'} handleclick={onHide} textButton='Delete' height={35} width={'25%'} />
        <ButtonDahbaord padding={'5px 2px'} handleclick={HandleLogout} textButton={textpopupBtn} height={35} width={'30%'} background='purple' />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default PopupCompanies;
