import { Skeleton } from "@mui/material";

 export const PendingCompSkeleton = () => {
    return (
      <div className='main-pending-colm' style={{background:'#747eef9c'}} >
        <div className='second-row-customer'>
          <Skeleton variant="circular" width={50} height={45} style={{ width: '22%',borderRadius:10 ,background:'#f1f2fd'}} />
          <div className='top-custorm-width' style={{ position: 'relative' }}>
            <Skeleton variant="text" width="80%" style={{background:'#f1f2fd'}} />
            <Skeleton variant="text" width="80%" style={{background:'#f1f2fd'}} />
            {/* <Skeleton variant="circular" width={24} height={24} style={{ position: 'absolute', top: '8px', right: '8px' }} /> */}
          </div>
        </div>
        <div className='second-row-customer' style={{ justifyContent: 'start', gap: 4, paddingTop: 10, paddingBottom: 5 ,paddingLeft:10}}>
          <Skeleton style={{background:'#f1f2fd'}}  variant="circular" width={14} height={14} />
          <Skeleton style={{background:'#f1f2fd'}} variant="text" width="80%" />
        </div>
        <div style={{ paddingTop: 10 }}>
          <Skeleton  variant="rectangular" width={'100%'} height={32} style={{borderRadius:5,background:'#f1f2fd'}} />
        </div>
      </div>
    );
  };
  