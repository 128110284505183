import React, { useEffect, useState } from 'react';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import { CiSearch } from "react-icons/ci";
import TopCustomers from 'admin/components/jobhistory/topcustomers';
import './Customer.css'
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import TopCustomer from 'admin/components/dashboardcom/TopCustomer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
function Customers() {
  const [data,setData] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigator = useNavigate()
  const reviews = [
    {
      id: 1,
      name: 'Kristin Watson',
      vist: '10:00 AM',
      spent: 200,
      status:'Completed',
      buyerName:'John Doe',
      carName:'Audi A8',
      carType:'Sedan',
      carPlate:'A-1234',
      OrderID:"CS-1095",
      OrderDate:'16 May 2024',
      paymnet:'1000'
   
    },
    {
      id: 2,
      name: 'Kristin Harry',
      vist: '10:00 AM',
      spent: 1200,
      status:'Not Completed',
      buyerName:'Harry Doe',
      carName:'G Wagon',
      carType:'Sedan',
      carPlate:'A-12114',
      OrderID:"SS-1095",
      OrderDate:'19 May 2024',
      paymnet:'1200'
    },
    {
      id: 3,
      name: 'Watson',
      vist: '10:00 AM',
      spent: 200,
      status:'Completed',
      buyerName:'John Doe',
      carName:'Audi A8',
      carType:'Sedan',
      carPlate:'A-1234',
      OrderID:"CS-1095",
      OrderDate:'16 May 2024',
      paymnet:'200'
    },
    {
      id: 4,
      name: 'Kristin Watson',
      vist: '10:00 AM ',
      spent: 200,
      status:'Not Completed',
      buyerName:'John Doe',
      carName:'Audi A8',
      carType:'Sedan',
      carPlate:'A-1234',
      OrderID:"CS-1095",
      OrderDate:'16 May 2024',
      paymnet:'1000'
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
      const apiUrl = 'https://washta-9006f93279b8.herokuapp.com/api/admin/JobHistory'; // Replace with your API endpoint
  
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("customersas",response.data.data)
        setData(response.data.data);
      } catch (err) {
        console.log(err.message);
      } 
    };
  
    fetchData();
  }, []);
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>Customers </h1>
    <div className="dashboard">
     
      <div className='styles-div'>

      <div className="chart-container">
        <div className='flex'>
        <div className='input-feild-dic-icon'>
        <CiSearch className='icon-serach-box-input' />
            <input placeholder='Search by service provider' className='input-feild-class-job-history'/>
        </div>     

        <div className='btn-job-histoiuyr'><ButtonDahbaord handleclick={()=>navigator('/super-admin-dashboard/customers/all-customers')} textButton="View all" height={35} width={'50%'} background='purple' /></div>
</div>
<div className="responsive-container">
    {reviews.map((item) => (
      <div key={item.id} className="responsive-item">
        <TopCustomer
        paymnet={item.paymnet}
        textpopupBtn="Share details"
        status={item.status}
        orderTime={item.vist}
        orderDate={item.OrderDate}
        orderId={item.OrderID}
        carPlate={item.carPlate}
        carType={item.carType}
        vist={item.vist}
         car={item.carName}
         buyerName={item.buyerName} show={true} Completed={item.status} name={item?.name} spent={item?.spent}  />
      </div>
    ))}
  </div>
      </div>
      
      </div>
      <div className='styles-div-text' >
      <TopCustomers/>
      </div>
    </div>
    </div>
    </div>
  );
}

export default Customers;
