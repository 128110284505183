import TopCampanies from 'admin/components/dashboardcom/TopCampanies';
import CompaniesCom from 'admin/components/jobhistory/companies/CompaniesCom';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import React, { useEffect } from 'react'
import { CiSearch } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';

const Campanies = () => {
    const reviews = [
        {
          id: 1,
          name: 'Kristin Watson',
          vist: '10:00 AM',
          spent: 200,
          status:'Completed',
          buyerName:'John Doe',
          Service:'Car wash',
          carType:'Sedan',
          carPlate:'A-1234',
          OrderID:"CS-1095",
          OrderDate:'16 May 2024',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          paymnet:'1300',
          img:'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg'
       
        },
        {
          id: 2,
          name: 'Kristin Harry',
          vist: '10:00 AM',
          spent: 1200,
          status:'Not Completed',
          buyerName:'Harry Doe',
          carName:'G Wagon',
          Service:'Car wash',
          carPlate:'A-12114',
          OrderID:"SS-1095",
          OrderDate:'19 May 2024',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          paymnet:'1200',
          img:'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg'

        },
        {
          id: 3,
          name: 'Watson',
          vist: '10:00 AM',
          spent: 200,
          status:'Completed',
          buyerName:'John Doe',
          Service:'Car wash',
          carType:'Sedan',
          carPlate:'A-1234',
          OrderID:"CS-1095",
          OrderDate:'16 May 2024',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          paymnet:'200',
          img:'https://media.autoexpress.co.uk/image/private/s--X-WVjvBW--/f_auto,t_content-image-full-desktop@1/v1563183740/autoexpress/2017/05/car_photo_438622.jpg'

        },
        {
          id: 4,
          name: 'Kristin Watson',
          vist: '10:00 AM ',
          spent: 200,
          status:'Not Completed',
          buyerName:'John Doe',
          Service:'Car wash',
          Loc:'B1, XYZ Mall Parking, Dubai , UAE',
          carPlate:'A-1234',
          OrderID:"CS-1095",
          OrderDate:'16 May 2024',
          paymnet:'1000',
          img:'https://blog-cdn.el.olx.com.pk/wp-content/uploads/2022/06/08130128/car-wash.jpg'

        },
      ];
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const navigator = useNavigate()
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>Companies </h1>
    <div className="dashboard">
     
      <div className='styles-div'>

      <div className="chart-container">
        <div className='flex'>
        <div className='input-feild-dic-icon'>
        <CiSearch className='icon-serach-box-input' />
            <input placeholder='Search by service provider' className='input-feild-class-job-history'/>
        </div>     

        <div className='btn-job-histoiuyr'><ButtonDahbaord handleclick={()=>navigator('/super-admin-dashboard/companies/all-companies')} textButton="View all" height={35} width={'40%'} background='purple' /></div>
</div>
<div className="responsive-container">
    {reviews.map((item) => (
      <div key={item.id} className="responsive-item">
        <CompaniesCom
        Loc={item.Loc}
        Service={item.Service}
        ImagePro={item.img}
        paymnet={item.paymnet}
        textpopupBtn="Share details"
        status={item.status}
        orderTime={item.vist}
        orderDate={item.OrderDate}
        orderId={item.OrderID}
        vist={item.vist}
         car={item.carName}
         buyerName={item.buyerName} show={true} Completed={item.status} name={item?.name} spent={item?.spent}  />
      </div>
    ))}
  </div>
      </div>
      
      </div>
      <div className='styles-div-text' >
     <TopCampanies/>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Campanies