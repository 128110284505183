import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './app.css'
import { AiOutlineClose } from "react-icons/ai";
import { PiChatsFill } from "react-icons/pi";

import img1 from '../../assets/images/LatestReView.png'
import img2 from '../../assets/images/acceptorder.png'
import img3 from '../../assets/images/avarage.png'
import bell from '../../assets/images/Bell.png'
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import DashbaordImgtext from 'components/latestOrder/DashbaordImgtext';
import TopCustomers from 'admin/components/dashboardcom/TopCustomers';
import TotalSalesChart from 'admin/components/dashboardcom/TotalSaleschart';
import TopCampanies from 'admin/components/dashboardcom/TopCampanies';
import { Popover, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
function AdminDashbaord() {
  const navigation = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
      <div className='row-div-admin-dashboard'>
    <h1 className='h1-all-heading width-asd'>Dashboard </h1>
    <div className='bell-div-css' style={{gap:5}}>
    <img onClick={handleOpenChange}  src={bell} style={{width:'5%',objectFit:'contain',cursor:'pointer'}} />
    <PiChatsFill onClick={()=>navigation('/super-admin-dashboard/Chats-screen')} style={{fontSize:27,color:"#747EEF",cursor:'pointer'}} />

    </div>
   
    <Popover
      content={
      
      <div className='row-div-admin-dashboard'>
 <a>Notifications</a>
 <AiOutlineClose onClick={hide} />

      </div>
     
    
    }
      // title="Title"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
</Popover>
    </div>
    <div className="dashboard">
     
      <div className='styles-div'>

<TotalSalesChart/>
      
      {/* <Line data={data} /> */}
      <div className="stats" style={{gap:20}}>
        <DashbaordImgtext heading='Total Orders' value='50' img={img1}/>
        <DashbaordImgtext heading='Cancelled Orders' value='42' img={img2}/>
        <DashbaordImgtext heading='Average Sales' value='08' img={img3}/>
      </div>
     <TopCustomers/>
      </div>
      <div className='styles-div-text' >
       <TopCampanies/>
      </div>
    </div>
    </div>
    </div>
  );
}

export default AdminDashbaord;
