import React, { useEffect, useState } from 'react';
import Sidebar from 'pages/sideBar/SideBar';
import { CiSearch } from "react-icons/ci";
import Invoice from 'components/invoice/Invoice';
import Skeleton from '@mui/material/Skeleton';
import '../app.css';
import './invoice.css';
import imgReviews from '../../../../assets/images/invoice.png';
import axios from 'axios';
import PopupInvoice from 'pages/clientDashbaord/popupinovie/popupInvoice';

function InvoicePage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const fetchData = async () => {
    const token = localStorage.getItem('accessToken'); // Retrieve token from local storage
    const apiUrl = 'https://washta-9006f93279b8.herokuapp.com/api/seller/order'; // Replace with your API endpoint

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("invoice", response.data.data);
      setData(response.data.data.reverse());
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  const handleDetailsClick = (invoice) => {
    setSelectedInvoice(invoice);
    setModalOpen(true);
  };

  function formatDate(isoString) {
    const date = new Date(isoString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <Sidebar />
      </div>
      <div className='div-Dashbaord'>
        <div className='main-inove-div-search'>
          <h1 className='h1-all-heading'>Invoices</h1>
          <div className='icon-postion-div-inoice'>
            <input placeholder='search invoice' className='invoice-search-icon-div' />
            <CiSearch style={{ position: 'absolute', right: 10, top: '22%', fontSize: 18 }} />
          </div>
        </div>
        <div className="dashboard">
          <div className='styles-div' style={{ width: '100%' }}>
            <div className='invoice-border'>
              <div className='chart-colm-div-heading'>
                <img src={imgReviews} style={{ width: '1.4em', objectFit: 'contain' }} />
                <h2 className='main-heading2'>History</h2>
              </div>
              {loading ? (
                Array.from(new Array(6)).map((_, index) => (
                  <div key={index} style={{background:'#747eef9c', padding: '10px 20px', display: 'flex', alignItems: 'center', borderRadius: '8px', border: '1px solid #ddd', marginBottom: '10px' }}>
                    <Skeleton variant="rectangular" width={48} height={48} style={{ marginRight: '10px', borderRadius: '8px',background:'#747eef9c'}} />
                    <div style={{ flexGrow: 1 }}>
                      <Skeleton variant="text" width="50%" height={30} style={{ marginBottom: '5px',background:'#f1f2fd' }} />
                      <Skeleton style={{background:'#f1f2fd'}} variant="text" width="30%" height={20} />
                    </div>
                    <Skeleton tyle={{background:'#f1f2fd'}} variant="text" width="10%" height={50} />
                  </div>
                ))
              ) : (
                data?.map((item) => (
                  <div key={item.id} style={{ paddingBottom: 15 }}>
                    <Invoice buttonClick={() => handleDetailsClick(item)} date={formatDate(item.date)} textInvoice={item.customerId} btnPurple='Delete' btnTranspraent='Details' />
                  </div>
                ))
              )}
              <PopupInvoice isOpen={modalOpen} onClose={() => setModalOpen(false)} data={selectedInvoice} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoicePage;
