import Sidebar from 'pages/sideBar/SideBar'
import React from 'react'

const AllRatingReviews = () => {
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<Sidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>All Rating & Reviews </h1>
    <div className="dashboard">
     
      <div className='styles-div'>
      <div className='invoice-border'></div>
        </div>
        </div>
        </div>

        </div>
  )
}

export default AllRatingReviews