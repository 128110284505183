import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AdminSidebar from 'admin/sidebar/AdminSidebar'
import React from 'react'
import { saveAs } from 'file-saver';
import './app.css'
import { useLocation } from 'react-router-dom';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import SatisSale from './StatisSale';

const DetailsSalePg = () => {
    const location = useLocation();
    const { item } = location.state || {};
    console.log(item)
    const detailsData = [
        { date: '13/02/24', time: '15:35', customerName: 'John Doe', plateNo: 'ABC-1234', vehicle: 'Jeep', status: 'Complete', serviceProvider: 'Quick Car wash', location: 'B 102, 123 street', invoiceNo: '127212', netPrice: 'AED 20', vat: 'AED 2', platformFee: '18%', totalPrice: 'AED 20' },
        { date: '13/02/24', time: '15:35', customerName: 'John Doe', plateNo: 'ABC-1234', vehicle: 'Jeep', status: 'Complete', serviceProvider: 'Quick Car wash', location: 'B 102, 123 street', invoiceNo: '127212', netPrice: 'AED 20', vat: 'AED 2', platformFee: '18%', totalPrice: 'AED 20' },
        { date: '13/02/24', time: '15:35', customerName: 'John Doe', plateNo: 'ABC-1234', vehicle: 'Jeep', status: 'Complete', serviceProvider: 'Quick Car wash', location: 'B 102, 123 street', invoiceNo: '127212', netPrice: 'AED 20', vat: 'AED 2', platformFee: '18%', totalPrice: 'AED 20' },
        { date: '13/02/24', time: '15:35', customerName: 'John Doe', plateNo: 'ABC-1234', vehicle: 'Jeep', status: 'Complete', serviceProvider: 'Quick Car wash', location: 'B 102, 123 street', invoiceNo: '127212', netPrice: 'AED 20', vat: 'AED 2', platformFee: '18%', totalPrice: 'AED 20' },
        // Add more rows as needed
      ];
    
      const exportToCSV = () => {
        const csvData = [
          ['Date', 'Time', 'Cus.Name', 'Plate No.', 'Vehicle', 'Status', 'Ser.Provider', 'Loc', 'Inv.No.', 'Price', 'VAT', 'Plat.Fee', 'Total Price'],
          ...detailsData.map(row => Object.values(row))
        ];
    
        const csvContent = csvData.map(e => e.join(",")).join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'details.csv');
      };
      
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>{item?.name} </h1>
    <div className="dashboard">
     <div className='styles-div addtionwidth-setting'>
      
    
      <div className="dashboard-customer-details" style={{width:'100%',}}>
    <h2>Details</h2>    
      <div className='div-customer-details-main' style={{display:'flex',flexWrap:'wrap'}}> 
            
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Service:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.service} readOnly />
            </div>
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Location:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.Loc} readOnly />
            </div>         
        </div>
      </div>

      <div className="dashboard-customer-details" style={{width:'100%',}}>
    <h2>Statistics</h2>    
      <div className='div-customer-details-main' style={{display:'flex',flexWrap:'wrap'}}> 
            <SatisSale/>
             
        </div>
      </div>

      <div className="dashboard-customer-details" style={{width:'100%',}}>
    <div className='div-row-type-sales'>
    <h2>Details</h2>    
    <ButtonDahbaord handleclick={()=>exportToCSV()} textButton="Export CSV" height={35} width={"13%"} background='purple' />
    </div>
   
      <div className='div-customer-details-main' style={{display:'flex',flexWrap:'wrap'}}> 
            
      <TableContainer component={Paper}>
                <Table>
                  <TableHead >
                    <TableRow >
                      <TableCell >Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Plate No.</TableCell>
                      <TableCell>Vehicle</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Service Provider</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Invoice No.</TableCell>
                      <TableCell>Net Price</TableCell>
                      <TableCell>VAT</TableCell>
                      <TableCell>Platform Fee</TableCell>
                      <TableCell>Total Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detailsData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>{row.time}</TableCell>
                        <TableCell>{row.customerName}</TableCell>
                        <TableCell>{row.plateNo}</TableCell>
                        <TableCell>{row.vehicle}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{row.serviceProvider}</TableCell>
                        <TableCell>{row.location}</TableCell>
                        <TableCell>{row.invoiceNo}</TableCell>
                        <TableCell>{row.netPrice}</TableCell>
                        <TableCell>{row.vat}</TableCell>
                        <TableCell>{row.platformFee}</TableCell>
                        <TableCell>{row.totalPrice}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
        </div>
      </div>

      </div>
    </div>
    </div>
    </div>
  )
}

export default DetailsSalePg