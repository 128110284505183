import React from 'react';
import Appimg from '../../../assets/images/usertopcustomer.png'
import './app.css'
import TopCustomer from './TopCustomer';

const reviews = [
  {
    id: 1,
    name: 'Kristin Watson',
    vist: '2 Visits ',
    spent: 200,
    status:'Completed'
 
  },
  {
    id: 2,
    name: 'Kristin Watson',
    vist: '5 Visits',
    spent: 1200,
    status:'Not Completed'
  },
  {
    id: 3,
    name: 'Kristin Watson',
    vist: '2 Visits',
    spent: 200,
    status:'Completed'
  },
  {
    id: 4,
    name: 'Kristin Watson',
    vist: '2 Visits ',
    spent: 200,
    status:'Not Completed'
  },
];

const TopCustomers = () => {
  return (
    <div className="latest-reviews">
     <div className='chart-colm-div-heading'> 
          <img src={Appimg} style={{width:'5%',objectFit:'contain'}}/>
          <h2 className='main-heading2'>Top Customer</h2>
          </div>
          <div className="responsive-container">
      {reviews.map((item) => (
        <div key={item.id} className="responsive-item">
         <TopCustomer show={false} Completed={item.status} name={item?.name} spent={item?.spent} vist={item?.vist}/>
        </div>
      ))}
      </div>
    </div>
  );
};

export default TopCustomers;
