import React from 'react'
import ClipBord from '../../../../assets/images/relatedtopic.png'
import Cancel from '../../../../assets/images/ordercencel.png'
import '../sellarShop/popup.css'
const RelatedTopics = () => {
  return (
    
    <div className='add-css-styling-cancel-div'>
    <img src={Cancel} style={{width:'1.4em',objectFit:'contain'}}/>
    <h5 className='h5-heaidng-invouice'>Order Cancellation</h5>
    </div>
  
  )
}

export default RelatedTopics