import React from 'react'
import './LatestOrder.css'
const LatestOrder = ({MainName,status,OrderId,OrderDate,Cost,orderdatespan}) => {
  return (
    <div className='latestOrder'>
       <div className='main-div-latest-order'>
       <div><h5 className='heading-h5-later'>{MainName||"Harry Makfee"}</h5></div>
       {status && (
          <div className='div-btn-button'>
            <button className='btn-main-later'>{status}</button>
          </div>
        )}
       {/* <div className='div-btn-button'><button className='btn-main-later'>{status}</button></div> */}
       </div>
       <div className='main-div-latest-order' style={{marginTop:15,marginBottom:2}}>
       <div className='div-order-inner'>
       <p className='paragph-heading'>Order ID:</p>
        <h5 className='heading-h5-later'>{OrderId}</h5>
        </div>
        <div className='div-order-inner'>
       <p className='paragph-heading'>Order Date:</p>
        <h5 className='heading-h5-later'>{OrderDate} {orderdatespan && ( <span style={{fontSize:9}}>AED</span>)}</h5>
        </div>
        <div className='div-order-inner'>
       <p className='paragph-heading'>Cost:</p>
        <h5 className='heading-h5-later'>{Cost} AED </h5>
        </div>
       
       </div>
        </div>
  )
}

export default LatestOrder