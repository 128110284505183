import Sidebar from 'pages/sideBar/SideBar'
import React, { useEffect } from 'react'
import '../app.css'
import RoundChat from '../../../../assets/images/roundchat.png'
import Chaticon from '../../../../assets/images/messageconatct.png'
import Supportcomp from 'components/supportcomp/Supportcomp'
import Icon1 from '../../../../assets/images/passwordSecurtiysupport.png'
import Icon2 from '../../../../assets/images/servicesupport.png'
import Icon3 from '../../../../assets/images/paymnetsupport.png'
import Icon4 from '../../../../assets/images/account-icon.png'
import ConactIcons from 'components/supportcomp/ContactIconSupport'
import MessageIcon from '../../../../assets/images/chatconatct.png'
import Emailicon from '../../../../assets/images/email.png'
import ChatCOnatct from '../../../../assets/images/phonecall.png'
import RelatedTopics from './RelatedTopics'
import ClipBord from '../../../../assets/images/relatedtopic.png'
import Cancel from '../../../../assets/images/ordercencel.png'
const SupportSeller = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const ratings = {
    1: 150,
    2: 70,
    3: 30,
    4: 15,
    5: 8,
  };
  const averageRating = 4.5;
  const totalReviews = 273;
  const recommendationPercentage = 88;
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<Sidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>Help & Support</h1>
    <div className="dashboard">
    <div className='styles-div'>
<div className='invoice-border'>
<div className='chart-colm-div-heading'> 
          <img src={RoundChat} style={{width:'1.4em',objectFit:'contain'}}/>
          <h2 className='main-heading2'>Categories we support</h2>
          </div>
          <div style={{display:'flex',gap:'20px'}}>
          <Supportcomp Icon={Icon4} Icondata="Account"/>
          <Supportcomp Icon={Icon1} Icondata="Password security"/>
          <Supportcomp Icon={Icon2} Icondata="Service"/>
          <Supportcomp Icon={Icon3} Icondata="Payment"/>  
          </div>  
</div>
<div className='invoice-border'>
<div className='chart-colm-div-heading'> 
          <img src={Chaticon} style={{width:'1.4em',objectFit:'contain'}}/>
          <h2 className='main-heading2'>Contact us at</h2>
          </div>
          <div style={{display:'flex',gap:'20px'}}>
          <ConactIcons timingSec="Mon - Sun" timing='9am - 9pm ' Heading="Chat with us" Icon={MessageIcon} />
          <ConactIcons timingSec="9am - 9pm |  Mon - Sun" timing='999 123 456' Heading="Call us at:" Icon={ChatCOnatct} />
          <ConactIcons timingSec="48 Hours" timing='Response time: ' Heading="Email us at:" Icon={Emailicon} />
          </div>  
</div>
</div>
<div className='styles-div-text' >

       <div className="orders">
      <div className='chart-colm-div-heading'> 
          <img src={ClipBord} style={{width:'1.4em',objectFit:'contain'}}/>
          <h2 className='main-heading2'>Related Topics</h2>
          </div>
    <RelatedTopics/>
    <RelatedTopics/>
    <RelatedTopics/>
    <RelatedTopics/>
    </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default SupportSeller