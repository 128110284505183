import PendingComp from 'admin/components/application/PendingComp';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { PendingCompSkeleton } from '../SkeletonPending';
import { ToastContainer, toast } from 'react-toastify';
function Rejected() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
const [data,setData] = useState([])
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);
console.log('rejected',data)
useEffect(() => {
  const fetchData = async () => {
    const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
    const apiUrl = 'https://washta-9006f93279b8.herokuapp.com/api/admin/businessBystatus?status=rejected'; // Replace with your API endpoint

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, []);
const handleAccept = async (id) => {
  const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
  const apiUrl = `https://washta-9006f93279b8.herokuapp.com/api/admin/businessApprove/${id}`; // Replace with your API endpoint for PATCH request

  try {
    const response = await axios.patch(apiUrl, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('Approved:', response.data);
    toast.success('successfully Approved user')
    setData(prevData => prevData.filter(item => item._id !== id));
  } catch (err) {
    console.error('Error approving:', err);
  }
};
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>Rejected Applications</h1>
    <div className="dashboard">
  
      <div className='styles-div' style={{width:'100%'}}>
      
      <div className='styles-div' style={{width:'100%'}}>
        <div  className='gap-btw-colm'>
        {loading ? (
                  Array.from(new Array(6)).map((_, index) => (
                    <div key={index} style={{ flex: '1 1 calc(25% - 16px)', marginBottom: '16px' }}>
                      <PendingCompSkeleton />
                    </div>
                  ))
                ) : (
            data?.map((item)=>(
                <div key={item.id} style={{flex: '1 1 calc(25% - 16px)'}}>
                  <PendingComp
                
                TextpopupbtnHandle={()=>handleAccept(item._id)}
                  location={item.business?.location}
                  document={item.business?.uploadDocument}
                  show={false}
                  accept="Accept"
                  reject="Reject"
                  ViewDocument="View Documents"
                  name={item.business?.companyName}
                  date={item.date}
                  time={item.time}
                  email={item.email}
                  valNumber={item.business?.VATNumber}
                  Ceo={item.business?.position  }
                  showBtn={false}
                  TextPopupbtnLast="Approve Request"
                      /></div> 
            )))}
        </div>
      
   
      </div>
      <ToastContainer/>
      </div>
      
    </div>
    </div>
    </div>
  );
}

export default Rejected;
