import React, { useEffect, useState } from 'react';
import './Become.css';
import ImgSingin from '../../assets/images/singup.png';
import Overly from '../../assets/images/overly.png';
import InputFeild from 'components/Input/Input';
import { Button } from 'components';
import Header from 'pages/Home1/Header';
import Footer from 'components/Footer';
import { MdOutlineAttachment } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Make sure axios is installed and imported

const AddBusniessDetails = () => {
  const navigation = useNavigate();
  const location = useLocation()
  const {item} = location.state || {};
  const token  =  localStorage.getItem('accessToken')
  const [formData, setFormData] = useState({
    companyName: '',
    location: '',
    vatNumber: '',
    uploadDoc: null,
    fullName: '',
    position: '',
    email: '',
  });
  console.log('token',token)
  const [uploading, setUploading] = useState(false); // To manage uploading state

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      uploadDoc: e.target.files[0], // Update uploadDoc with selected file
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      setUploading(true);
      const uploadFormData = new FormData();
      uploadFormData.append('file', formData.uploadDoc);

      const uploadResponse = await axios.post('https://washta-9006f93279b8.herokuapp.com/api/uploadFile', uploadFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // Include authentication token if needed
          // 'Authorization': `Bearer ${token}`
        }
      });

      const documentUrl = uploadResponse.data.data.path 
console.log('fileurlasdasd',documentUrl)
      const businessDetails = {
        uploadDocument: documentUrl,
        companyName: formData.companyName,
        location: formData.location,
        VATNumber: formData.vatNumber,
        fullName: formData.fullName,
        position: formData.position,
        email: formData.email
      };
     console.log(businessDetails)
      // Send business details to the second API
      const businessResponse = await axios.post(`https://washta-9006f93279b8.herokuapp.com/api/seller/Business/${item?.user?.id}`, businessDetails, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    // Log the response from the second API
    console.log('Response from /api/seller/business:', businessResponse.data);
      console.log('Form data submitted successfully:', businessDetails);

      navigation('/not-verified');

      // Reset form data
      setFormData({
        companyName: '',
        location: '',
        vatNumber: '',
        uploadDoc: null,
        fullName: '',
        position: '',
        email: '',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div style={{ position: 'relative' }}>
        <div className='row'>
          <img src={Overly} className='overlyClassName' alt="Logo" />
          <div className='addbussiness-detsilsx'>
            <div className='innerDiv-form'>
              <h1 className='h1-heaing'> Add your business details </h1>
              <InputFeild type='text' label="Company Name" placeholder="Company Name" value={formData.companyName} onChange={(value) => handleChange('companyName', value)} />
              <div className='gapmargin'> <InputFeild type='text' label="Location" placeholder="Location" value={formData.location} onChange={(value) => handleChange('location', value)} /></div>
              <div className='gapmargin'> <InputFeild type='text' label="VAT Number" placeholder="VAT Number" value={formData.vatNumber} onChange={(value) => handleChange('vatNumber', value)} /></div>
              <div className='gapmargin' style={{display:'flex',position:'relative'}}> 
                <input
                  type="file"
                  id="fileUpload"
                  accept=".pdf"
                  onChange={handleFileChange}
                  style={{
                    display: 'none', // Hide the default file input
                  }}
                />
                <label htmlFor="fileUpload" style={{ borderBottom: '1px solid white', cursor: 'pointer', width:'100%', color:'#d9dbed', paddingBottom:10, fontSize:'16px' }}>
                  Upload Documents
                </label>
                <MdOutlineAttachment style={{position:'absolute',right:'10px',color:'white',fontSize:'20px',transform:'rotate(45deg)'}} />
              </div>
              <div className='gapmargin'> <InputFeild type='text' label="Full Name" placeholder="Full Name" value={formData.fullName} onChange={(value) => handleChange('fullName', value)} /></div>
              <div className='gapmargin'> <InputFeild type='text' label="Position" placeholder="Position" value={formData.position} onChange={(value) => handleChange('position', value)} /></div>
              <div className='gapmargin'> <InputFeild type='email' label="Email" placeholder="Email" value={formData.email} onChange={(value) => handleChange('email', value)} /></div>
              <Button onClick={handleFormSubmit} shape="round" className="min-w-[100%] sm:px-1" style={{ fontSize: '16px', background: 'white', color: 'black', marginTop: '20px', fontFamily: "'General Sans', sans-serif" }} disabled={uploading}>
                {uploading ? 'Uploading...' : 'Finish'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddBusniessDetails;
