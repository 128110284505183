// src/utils/formatDate.js
import moment from 'moment-timezone';

export const formatDateconvert = (dateString) => {
  return moment.tz(dateString, 'UTC').format('DD MMM YYYY');
};
export const formatDate = (timestamp) => {
  return moment(timestamp).format('MMM DD');
};
export const formatTimeInTimezone = (timestamp, timezone) => {
  return moment(timestamp).tz(timezone).format('hh:mm A');
};