import React, { useState } from 'react';
import './app.css'
import PopupCompanies from './PopupCompanies';
import InvoicePopup from '../InvoicePopup';
const CompaniesCom = ({ name, textpopupBtn, paymnet,spent,orderDate, Completed, show ,Service,Loc,vist,orderId,orderTime,status,ImagePro}) => {
  const [modalShow, setModalShow] = useState(false);
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [popupData, setPopupData] = useState({});

  const handleViewDocumentsClick = () => {
    setPopupData({ name,paymnet, orderTime, vist, spent,orderDate, Completed,Service,Loc,orderId ,status});
    setModalShow(true);
  };
  const handleViewInvoice = () => {
    setModalShow(false);
    setInvoiceShow(true);
  };

  return (
    <div className='tope-customer-com-main' >
      <div className='second-row-customer' style={{cursor:'pointer'}}
       onClick={handleViewDocumentsClick}
       >
        <img src={ImagePro} className='image-pro-companies' />
        <div className='top-custorm-width'>
          <h5 className='h5-class-top-class'>{name}</h5>
          <p className='paragprh-customer-com'>{vist}</p>
        </div>
      </div>
      {show && (
        <div className='second-row-customer' style={{ paddingTop: 16 ,cursor:'pointer'}} 
        onClick={handleViewDocumentsClick}
        >
          <p className='paragprh-customer-com'>Status</p>
          <p className='heading-sub-customer-com'>{Completed}</p>
        </div>
      )}
      <PopupCompanies
        showBtn={true}
        HandleLogout={''}
        show={modalShow}
        onHide={() => setModalShow(false)}
        popupData={popupData}
        textpopupBtn={textpopupBtn}
        onViewInvoice={handleViewInvoice}
      />
      <InvoicePopup
        show={invoiceShow}
        onHide={() => setInvoiceShow(false)}
        invoiceData={popupData}
      />
      <div className='second-row-customer' style={{ paddingTop: show ? 5 : 15, border: 'none',cursor:'pointer' }}
       onClick={handleViewDocumentsClick}
       >
        <p className='paragprh-customer-com' >Total Spent</p>
        <p className='heading-sub-customer-com'>{spent} <span style={{ fontSize: 9 }}>AED</span></p>
      </div>
    </div>
  );
}

export default CompaniesCom;
